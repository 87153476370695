import { useSearchParams, useNavigate } from 'react-router-dom'

interface IUseSearch {
	name: string
	cb: (data: string) => void
}

export const useSearch = ({ name, cb }: IUseSearch) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const handleSearch = (data: any) => {
		const { value } = data.target

		if (value.length >= 1) {
			searchParams.set(name, value)
			searchParams.set('ordering', '-start_date')
		} else {
			searchParams.delete(name)
		}

		searchParams.delete('page')
		cb(searchParams.toString())
		navigate({ search: searchParams.toString() })
	}

	return {
		handleSearch
	}
}
