import { useTranslation } from 'libs'
import { NavLink, useNavigate } from 'react-router-dom'

import EllipsisMenu from 'components/Ui/EllipsisMenu'
import { Wrapper, Mobile } from './styles'

interface ITag {
	id: number
	name: string
	slug: string
	openDeleteModal: (id: number) => void
}

const Tag = ({ id, name, slug, openDeleteModal }: ITag) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/tags/${id}`)

	const ellipsisMenuOptions = [
		{
			label: t('tags.ellipsisMenu.edit'),
			action: redirectToEditPage
		},
		{
			label: t('tags.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<Wrapper>
			<span>
				{name ? (
					<>
						<Mobile>{t('tags.tag.name')}</Mobile> <NavLink to={`/tags/${id}`}>{name}</NavLink>
					</>
				) : (
					''
				)}
			</span>
			<span>
				{slug ? (
					<>
						<Mobile>{t('tags.tag.slug')}</Mobile> {slug}
					</>
				) : (
					''
				)}
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default Tag
