import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'libs'
import { Column } from '@table-library/react-table-library/compact'
import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
// import { Currency } from 'components/Ui'
import { InfoHeader, InfoHeaderContainer, InfoHeaderLink, SalesTableWrapper } from './styles'
import { customTableStyles } from 'styles/general'
import { format, parseISO } from 'date-fns'
import { useFetchData } from 'hooks'
import { Spinner } from 'components/Ui/Spinner'
// import {
// 	Header as TableHeader,
// 	List as TableList,
// 	Container as TableContainer
// } from 'components/Ui/Table'

export interface BookingSale {
	id: string
	created: string
	receipt_reference: string
	product_name: string
}

export interface BookingSalesTableProps {
	booking_id?: string
}

export const BookingSalesTable = ({ booking_id }: BookingSalesTableProps) => {
	const { t } = useTranslation()
	const [salesData, setSalesData] = useState({ nodes: [] })

	const { isFetching: salesLoading } = useFetchData({
		name: 'fetchSales',
		query: `booking_id=${booking_id}`,
		endpoint: '/sales/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setSalesData({ nodes: results })
			}
		}
	})
	if (salesData?.nodes?.length == 0) {
		// TODO: empty sales content
		return (
			<>
				<InfoHeader>Sales</InfoHeader>
				<div>No sales yet</div>
			</>
		)
	}

	const columns: Column[] = [
		{
			label: t('sales.tableHeaders.date'),
			renderCell: (item: any) => (
				<NavLink to={`/sales/${item.id}`}>{format(parseISO(item.created), 'yy-MM-dd')}</NavLink>
			)
		}
		// {
		// 	label: t('sales.tableHeaders.reference'),
		// 	renderCell: (item: any) => <>{item.receipt_reference}</>
		// },
		// // {
		// // 	label: 'Name',
		// // 	renderCell: (item: any) => <>{item.product_name}</>
		// // },
		// // {
		// // 	label: 'SKU',
		// // 	renderCell: (item: any) => <>{item.product_sku}</>
		// // },
		// {
		// 	label: t('sales.tableHeaders.price'),
		// 	renderCell: (item: any) => <Currency price={item.product_unit_price}></Currency>
		// },
		// {
		// 	label: t('sales.tableHeaders.quantity'),
		// 	renderCell: (item: any) => <>{item.quantity}</>
		// },
		// {
		// 	label: t('sales.tableHeaders.discount'),
		// 	renderCell: (item: any) => <Currency price={item.discount}></Currency>
		// },
		// {
		// 	label: 'Subtotal',
		// 	renderCell: (item: any) => <Currency price={item.subtotal}></Currency>
		// },
		// {
		// 	label: t('sales.tableHeaders.commission'),
		// 	renderCell: (item: any) => <div>{item.commission_percentage}%</div>
		// },
		// {
		// 	label: t('sales.tableHeaders.commissionTotal'),
		// 	renderCell: (item: any) => <Currency price={item.commission_amount}></Currency>
		// }
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
			${customTableStyles.Table};
			--data-table-library_grid-template-columns: 65px repeat(${columns.length - 1}, 1fr);
		`
	}

	const theme = useTheme([getTheme(), desktopTableStyles])

	return (
		<>
			<InfoHeaderContainer>
				<InfoHeader>Sales</InfoHeader>
				{!!salesData?.nodes.length && (
					<InfoHeaderLink to={'/sales?booking_id=' + booking_id}>View all</InfoHeaderLink>
				)}
			</InfoHeaderContainer>
			<SalesTableWrapper>
				{salesLoading && <Spinner></Spinner>}
				{salesData.nodes != null && Array.isArray(salesData?.nodes) && salesData?.nodes.length ? (
					<CompactTable
						columns={columns}
						data={salesData}
						theme={theme}
						isLoading={salesLoading}
						layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}></CompactTable>
				) : (
					<></>
				)}
			</SalesTableWrapper>
		</>
	)
}
