import { useState, useContext } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'libs'
import { theme } from 'twin.macro'
import {
	BanknotesIcon,
	CircleStackIcon,
	ChartPieIcon,
	ReceiptPercentIcon,
	Bars2Icon,
	CurrencyDollarIcon
} from '@heroicons/react/24/outline'
import { format, parseISO } from 'date-fns'
import { Column } from '@table-library/react-table-library/compact'
import { useMutation } from 'react-query'

import { Sale } from 'components'
import FiltersWrapper from 'components/FiltersWrapper'
import Header from 'components/Header'
import MobileFilters from 'components/Filters/mobile'
import Select from 'components/Ui/Select'
import FiltersModal from 'components/Modals/filters'
import SortModal from 'components/Modals/sort'
import Filters from 'components/Filters'
import EmptyTable from 'components/Ui/Table/Empty'
import { Currency } from 'components/Ui'
import ConfirmModal from 'components/Modals/confirm'
import { IActionFilter } from 'interfaces/filters.interface'
import FormatModal from 'components/Modals/format'
import { IOption } from 'interfaces/select.interface'
import { defaultConfig } from 'config/default'
import { downloadAs } from 'utils/downloadFile'
import {
	Content,
	Summaries,
	StyledSummary,
	FiltersContainer,
	StyledSearchInput,
	CategorySelect,
	StyledBoothSelect as StyledBookingSelect,
	StyledUsersSelect,
	DesktopSortSelect,
	StyledTable,
	FiltersModalContent,
	FiltersRow,
	StyledTable2,
	BookingCell,
	BookingReference,
	CommissionPercentage,
	Sku,
	UserEmail,
	UserNameCell,
	ProductWrapperName,
	ProductWrapper
} from './styles'
import SortSelect from 'components/Ui/Select/Sort'
import TableImage from 'components/Ui/Table/Image'
import { TableWrapper, customTableStyles } from 'styles/general'
import { AuthContext, DashboardContext } from 'store'
import {
	useFetchData,
	useTableSelect,
	useUserRoles,
	useDevice,
	useAxiosPrivate,
	useToast,
	usePagination,
	useSearch,
	useFilter,
	useFetchInfinite,
	useModal
} from 'hooks'

const SalesPage = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handlePagination } = usePagination(setQuery)
	const { handleSearch } = useSearch({ name: 'search', cb: setQuery })
	const { handleFilter } = useFilter(setQuery)
	const [checkedSales, setCheckedSales] = useState<number[]>([])
	const axiosPrivate = useAxiosPrivate()
	const [chosenExportFormat, setChosenExportFormat] = useState<number | string | null>(null)
	const { isUser } = useUserRoles()
	const userRoles = useUserRoles()
	const [salesData, setSalesData] = useState({ nodes: [] })
	const { select } = useTableSelect(salesData, onSelectChange)
	const { isMobileOrTablet } = useDevice()
	const { auth } = useContext(AuthContext)
	const dashboardCtx = useContext(DashboardContext)

	const {
		isOpen: isFiltersOpen,
		openModal: openFiltersModal,
		closeModal: closeFiltersModal
	} = useModal()
	const { isOpen: isSortOpen, openModal: openSortModal, closeModal: closeSortModal } = useModal()
	const {
		isOpen: isCommissionModalOpen,
		openModal: openCommissionModal,
		closeModal: closeCommissionModal
	} = useModal()
	const {
		isOpen: isPayoutModalOpen,
		openModal: openPayoutModal,
		closeModal: closePayoutModal
	} = useModal()
	const {
		isOpen: isExportModalOpen,
		openModal: openExportModal,
		closeModal: closeExportModal
	} = useModal()
	const {
		isOpen: isFormatModalOpen,
		openModal: openFormatModal,
		closeModal: closeFormatModal
	} = useModal()
	const commissionSuccessMsg = useToast({
		type: 'success',
		message: t('sales.modal.commission.success')
	})
	const commissionFailMsg = useToast({
		type: 'error',
		message: t('sales.modal.commission.error')
	})
	const payoutSuccessMsg = () =>
		useToast({
			type: 'success',
			message: t('sales.modal.payout.success')
		})
	const payoutFailMsg = (msg: string) => {
		useToast({
			type: 'error',
			message: msg ?? t('sales.modal.payout.error')
		})()
	}
	const exportSuccessMsg = useToast({
		type: 'success',
		message: t('sales.modal.export.success')
	})
	const exportFailMsg = useToast({
		type: 'error',
		message: t('sales.modal.export.error')
	})

	const {
		data: bookings,
		isLoading: loadingBookings,
		fetchNextPage: fetchNextBooths
	} = useFetchInfinite({
		name: 'fetchBookings',
		endpoint: '/bookings/',
		query: 'is_active=true',
		enabled: !!auth.accessToken
	})

	const {
		data: users,
		isLoading: loadingUsers,
		fetchNextPage: fetchNextUsers
	} = useFetchInfinite({
		name: 'fetchUsers',
		endpoint: '/accounts/',
		enabled: !!auth.accessToken && !isUser
	})

	const { data: sales, isFetching: salesLoading } = useFetchData({
		name: 'fetchSales',
		query,
		endpoint: '/sales/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setSalesData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const { data: categories, isLoading: loadingCategories } = useFetchData({
		name: 'fetchCategories',
		endpoint: '/categories/',
		options: {
			enabled: !!auth.accessToken
		}
	})

	const { mutate: requestPayoutMutation } = useMutation(
		() => {
			return axiosPrivate.post('/sales/request_payout/', { ids: checkedSales })
		},
		{
			onSuccess: () => {
				payoutSuccessMsg()
			},
			onError: (err: any) => {
				payoutFailMsg(err.response.data.detail)
			},
			onSettled: () => {
				closePayoutModal()
			}
		}
	)

	const { mutate: requestCommissionMutation } = useMutation(
		() => {
			return axiosPrivate.post('/sales/recalculate_commission/', { ids: checkedSales })
		},
		{
			onSuccess: () => {
				commissionSuccessMsg()
			},
			onError: () => {
				commissionFailMsg()
			},
			onSettled: () => {
				closeCommissionModal()
			}
		}
	)

	const { mutate: exportMutation, isLoading: isExportLoading } = useMutation(
		() => {
			return axiosPrivate.post('/sales/export/', { format: chosenExportFormat, ids: checkedSales })
		},
		{
			onSuccess: (res) => {
				const format = defaultConfig.exportFormatOptions
					.find((format) => format.value === chosenExportFormat)
					?.label.toLowerCase()

				if (format) {
					downloadAs({ file: res.data, name: 'sales-export', format })
				}

				setCheckedSales([])
				exportSuccessMsg()
			},
			onError: () => {
				exportFailMsg()
			},
			onSettled: () => {
				closeFormatModal()
			}
		}
	)

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value

		switch (action) {
			case 'commission':
				openCommissionModal()
				break
			case 'payout':
				openPayoutModal()
				break
			case 'export':
				openExportModal()
				break
		}
	}

	const requestExport = () => {
		exportMutation()
	}

	const handleExportModal = () => {
		closeExportModal()
		openFormatModal()
	}

	const handleFormatChange = (data: IOption) => {
		data?.value?.toString() ? setChosenExportFormat(data.value) : setChosenExportFormat(null)
	}

	const tableHeaders = [
		{
			id: 'product',
			label: t('sales.tableHeaders.product')
		},
		{
			id: 'date',
			label: t('sales.tableHeaders.date')
		},
		{
			id: 'reference',
			label: t('sales.tableHeaders.reference')
		},
		...(userRoles
			? [
					{
						id: 'user',
						label: t('sales.tableHeaders.user')
					}
			  ]
			: []),
		{
			id: 'booking',
			label: t('sales.tableHeaders.booking')
		},
		{
			id: 'quantity',
			label: t('sales.tableHeaders.quantity')
		},
		{
			id: 'price',
			label: t('sales.tableHeaders.price')
		},
		{
			id: 'discount',
			label: t('sales.tableHeaders.discount')
		},
		{
			id: 'commission',
			label: t('sales.tableHeaders.commission')
		},
		{
			id: 'commissionTotal',
			label: t('sales.tableHeaders.commissionTotal')
		},
		{
			id: 'payout',
			label: t('sales.tableHeaders.payout')
		}
	]

	const sortingFilters = {
		id: 'ordering',
		options: [
			{
				value: 'created',
				label: `${t('sales.filters.sort.options.created')} asc`
			},
			{
				value: '-created',
				label: `${t('sales.filters.sort.options.created')} desc`
			},
			{
				value: 'quantity',
				label: `${t('sales.filters.sort.options.quantity')} asc`
			},
			{
				value: '-quantity',
				label: `${t('sales.filters.sort.options.quantity')} desc`
			},
			{
				value: 'category',
				label: `${t('sales.filters.sort.options.category')} asc`
			},
			{
				value: '-category',
				label: `${t('sales.filters.sort.options.category')} desc`
			},
			{
				value: 'product',
				label: `${t('sales.filters.sort.options.product')} asc`
			},
			{
				value: '-product',
				label: `${t('sales.filters.sort.options.product')} desc`
			},
			{
				value: 'product__name',
				label: `${t('sales.filters.sort.options.productName')} asc`
			},
			{
				value: '-product__name',
				label: `${t('sales.filters.sort.options.productName')} desc`
			},
			{
				value: 'product__category',
				label: `${t('sales.filters.sort.options.productCategory')} asc`
			},
			{
				value: '-product__category',
				label: `${t('sales.filters.sort.options.productCategory')} desc`
			},
			{
				value: 'booking__booth',
				label: `${t('sales.filters.sort.options.booking')} asc`
			},
			{
				value: '-booking__booth',
				label: `${t('sales.filters.sort.options.booking')} desc`
			}
		]
	}

	const filters = [
		{
			id: 'actions',
			label: t('sales.filters.actions.label'),
			placeholder: t('sales.filters.actions.placeholder'),
			options: [
				{
					value: 'commission',
					label: t('sales.filters.actions.options.commission'),
					isDisabled: checkedSales.length == 0
				},
				{
					value: 'payout',
					label: t('sales.filters.actions.options.payout'),
					isDisabled: checkedSales.length == 0
				},
				{
					value: 'export',
					label: t('sales.filters.actions.options.export')
				}
			]
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedSales(state.ids)
	}

	const columns: Column[] = [
		{
			label: t('sales.tableHeaders.product'),
			renderCell: (item: any) => (
				<ProductWrapper>
					<TableImage src={item.product_image} alt="" />
					<ProductWrapperName>
						<NavLink style={{ color: theme`colors.blueRibbon` }} to={`/sales/${item.id}`}>
							{item.product_name}
						</NavLink>
						<Sku>{item.product_sku}</Sku>
					</ProductWrapperName>
				</ProductWrapper>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('sales.tableHeaders.date'),
			renderCell: (item: any) => (
				<NavLink to={`/sales/${item.id}`}>
					<div>
						<div>{format(parseISO(item.created), 'yyyy-MM-dd')}</div>
						<div>{format(parseISO(item.created), 'HH:mm:ss')}</div>
					</div>
				</NavLink>
			)
		},
		{
			renderCell: (item: any) => item.receipt_reference,
			label: t('sales.tableHeaders.reference')
		},
		...(userRoles.isStaff
			? [
					{
						renderCell: (item: any) => (
							<UserNameCell>
								<div>{item.account_name}</div>
								<UserEmail>{item.account_email}</UserEmail>
							</UserNameCell>
						),
						label: t('sales.tableHeaders.user')
					}
			  ]
			: [
					{
						label: '',
						renderCell: () => ''
					}
			  ]),
		{
			label: t('sales.tableHeaders.booking'),
			renderCell: (item: any) => {
				if (item.booking?.id) {
					return (
						<BookingCell>
							<div>
								<span>{item.booking?.booth?.booth_type?.name}</span>
								{item.booking?.booth?.number && <span> #{item.booking.booth.number}</span>}
							</div>
							<BookingReference>
								<span>#{item.booking?.reference}</span> <span>{item.booking.start_date}</span>
							</BookingReference>
						</BookingCell>
					)
				} else {
					return <div></div>
				}
			}
		},

		{
			renderCell: (item: any) => item.quantity,
			label: t('sales.tableHeaders.quantity')
		},
		{
			renderCell: (item: any) => <Currency price={item.product_unit_price} />,
			label: t('sales.tableHeaders.price')
		},
		{
			renderCell: (item: any) => <Currency price={item.subtotal} />,
			label: t('sales.tableHeaders.subtotal')
		},
		{
			renderCell: (item: any) => <Currency price={item.discount} />,
			label: t('sales.tableHeaders.discount')
		},
		{
			renderCell: (item: any) => <Currency price={item.total} />,
			label: t('sales.tableHeaders.total')
		},
		{
			renderCell: (item: any) => (
				<div>
					<span>
						<Currency price={item.commission} />
					</span>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<CommissionPercentage>{`(${+item.commission_percentage}%)`}</CommissionPercentage>
				</div>
			),
			label: t('sales.tableHeaders.commissionTotal')
		},

		{
			renderCell: (item: any) => (
				<Currency
					price={{
						amount: item.net_profit,
						currency: item.total.currency
					}}
				/>
			), // <Currency price={item.net_profit} />,
			label: t('sales.tableHeaders.netProfit')
		},
		{
			renderCell: (item: any) => (item.payout ? `#${item.payout}` : ''),
			label: t('sales.tableHeaders.payout')
		}
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
			${customTableStyles.Table};
			--data-table-library_grid-template-columns: 40px repeat(${columns.length}, 1fr);
		`
	}

	return (
		<>
			<Header title={t('sales.title')} handleSearch={handleSearch} />
			<Content>
				<Summaries>
					<StyledSummary
						title={t('sales.summary.balance')}
						content={
							<Currency
								price={{
									amount: dashboardCtx?.dashboard?.data?.balance as string,
									currency: dashboardCtx?.dashboard?.data?.currency as string
								}}
							/>
						}
						icon={<BanknotesIcon />}
						iconBgColor={theme`colors.zahan`}
						iconColor={theme`colors.apple`}
					/>
					<StyledSummary
						title={t('sales.summary.quantity')}
						content={sales?.data.summary.total_quantity}
						icon={<CircleStackIcon />}
						iconBgColor={theme`colors.charlotte`}
						iconColor={theme`colors.elm`}
					/>
					<StyledSummary
						title={t('sales.summary.subtotal')}
						content={
							<Currency
								price={{
									amount: sales?.data?.summary.subtotal.amount,
									currency: sales?.data?.summary.subtotal.currency
								}}
							/>
						}
						icon={<ChartPieIcon />}
						iconBgColor={theme`colors.blueChalk`}
						iconColor={theme`colors.eminence`}
					/>
					<StyledSummary
						title={t('sales.summary.discount')}
						content={
							<Currency
								price={{
									amount: sales?.data?.summary.discount.amount,
									currency: sales?.data?.summary.discount.currency
								}}
							/>
						}
						icon={<ReceiptPercentIcon />}
						iconBgColor={theme`colors.bananaMania`}
						iconColor={theme`colors.hacienda`}
					/>
					<StyledSummary
						title={t('sales.summary.total')}
						content={
							<Currency
								price={{
									amount: sales?.data?.summary.total.amount,
									currency: sales?.data?.summary.total.currency
								}}
							/>
						}
						icon={<BanknotesIcon />}
						iconBgColor={theme`colors.roseBud`}
						iconColor={theme`colors.prairieSand`}
					/>
					<StyledSummary
						title={t('sales.summary.commission')}
						content={
							<Currency
								price={{
									amount: sales?.data?.summary.commission.amount,
									currency: sales?.data?.summary.commission.currency
								}}
							/>
						}
						icon={<CurrencyDollarIcon />}
						iconBgColor={theme`colors.periwinkle`}
						iconColor={theme`colors.governorBay`}
					/>
					<StyledSummary
						title={t('sales.summary.remaining')}
						content={
							<Currency
								price={{
									amount: sales?.data?.summary.to_payout.amount,
									currency: sales?.data?.summary.to_payout.currency
								}}
							/>
						}
						icon={<Bars2Icon />}
						iconBgColor={theme`colors.gossip`}
						iconColor={theme`colors.forrestGreen`}
					/>
				</Summaries>
				<FiltersWrapper>
					<FiltersContainer>
						<FiltersRow>
							<StyledSearchInput
								label={t('sales.filters.search.label')}
								placeholder={t('sales.filters.search.placeholder')}
								onChange={handleSearch}
							/>
							<CategorySelect
								isLoading={loadingCategories}
								key="category"
								id="category"
								placeholder={t('filters.placeholder')}
								label={t('filters.category')}
								options={
									!loadingCategories &&
									categories != null &&
									Array.isArray(categories?.data?.results)
										? categories?.data?.results.map((option: any) => ({
												label: option.name,
												value: option.id
										  }))
										: []
								}
								onChange={(data) =>
									handleFilter({ id: 'product__category', value: data?.value || null })
								}
							/>
							<StyledBookingSelect
								isLoading={loadingBookings}
								key="booking"
								id="booking"
								placeholder={t('filters.placeholder')}
								label={t('filters.booking')}
								options={
									!loadingBookings && bookings != null && Array.isArray(bookings?.pages)
										? bookings?.pages
												.reduce((total: any, page: any) => {
													return [...total, ...page.results]
												}, [])
												.map((booking: any) => {
													return {
														label: booking?.booth?.number
															? `#${booking.booth.number} ${booking.booth.booth_type.name} - (#${booking.ref_nr} ${booking.start_date})`
															: `#${booking.ref_nr} ${booking.start_date}`,
														value: booking.id
													}
												})
										: []
								}
								handleInfiniteScroll={fetchNextBooths}
								onChange={(data: any) =>
									handleFilter({ id: 'booking_id', value: data?.value || null })
								}
							/>
							{!isUser && (
								<StyledUsersSelect
									isLoading={loadingUsers}
									key="user"
									id="user"
									placeholder={t('filters.placeholder')}
									label={t('filters.user')}
									options={
										!loadingUsers && users != null && Array.isArray(users?.pages)
											? users?.pages
													.reduce((total: any, page: any) => {
														return [...total, ...page.results]
													}, [])
													.map((user: any) => {
														return {
															label: `${user.email}`,
															value: user.id
														}
													})
											: []
									}
									handleInfiniteScroll={fetchNextUsers}
									onChange={(data: any) =>
										handleFilter({ id: 'account', value: data?.value || null })
									}
								/>
							)}

							{!isUser && (
								<Filters
									filters={filters}
									disableActions={false}
									handleFilter={handleActionFilter}
								/>
							)}
						</FiltersRow>
						<DesktopSortSelect {...sortingFilters} onChange={handleFilter} />
						<MobileFilters openFilters={openFiltersModal} openSort={openSortModal} />
					</FiltersContainer>
				</FiltersWrapper>
			</Content>
			{salesData.nodes != null && Array.isArray(salesData?.nodes) && salesData?.nodes.length ? (
				<TableWrapper>
					{isMobileOrTablet ? (
						<StyledTable
							isLoading={salesLoading}
							prev={sales?.data?.previous}
							next={sales?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{sales?.data?.results.map((sale: any) => (
								<Sale key={sale.id} {...sale} />
							))}
						</StyledTable>
					) : (
						<StyledTable2
							columns={columns}
							data={salesData}
							isLoading={salesLoading}
							select={select}
							prev={sales?.data?.previous}
							next={sales?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}
				</TableWrapper>
			) : (
				<EmptyTable isLoading={salesLoading} />
			)}

			<FiltersModal isOpened={isFiltersOpen} closeModal={closeFiltersModal}>
				<FiltersModalContent>
					<Select
						isLoading={loadingBookings}
						key="booking"
						id="booking"
						placeholder={t('filters.placeholder')}
						label={t('filters.booking')}
						options={
							!loadingBookings && bookings != null && Array.isArray(bookings?.pages)
								? bookings?.pages
										.reduce((total: any, page: any) => {
											return [...total, ...page.results]
										}, [])
										.map((booking: any) => {
											return {
												label: booking?.booth?.number
													? `#${booking.booth.number} ${booking.booth.booth_type.name} - (#${booking.ref_nr} ${booking.start_date})`
													: `#${booking.ref_nr} ${booking.start_date}`,
												value: booking.id
											}
										})
								: []
						}
						handleInfiniteScroll={fetchNextBooths}
						onChange={(data: any) => handleFilter({ id: 'booking_id', value: data?.value || null })}
					/>
					<Select
						isLoading={loadingCategories}
						key="category"
						id="category"
						placeholder={t('filters.placeholder')}
						label={t('filters.category')}
						options={
							!loadingCategories && categories != null && Array.isArray(categories?.data?.results)
								? categories?.data?.results.map((option: any) => ({
										label: option.name,
										value: option.id
								  }))
								: []
						}
						onChange={(data) =>
							handleFilter({ id: 'product__category', value: data?.value || null })
						}
					/>
					{!isUser && (
						<Select
							isLoading={loadingUsers}
							key="user"
							id="user"
							placeholder={t('filters.placeholder')}
							label={t('filters.user')}
							options={
								!loadingUsers && users != null && Array.isArray(users?.pages)
									? users?.pages
											.reduce((total: any, page: any) => {
												return [...total, ...page.results]
											}, [])
											.map((user: any) => {
												return {
													label: `${user.email}`,
													value: user.id
												}
											})
									: []
							}
							handleInfiniteScroll={fetchNextUsers}
							onChange={(data: any) => handleFilter({ id: 'account', value: data?.value || null })}
						/>
					)}
				</FiltersModalContent>
			</FiltersModal>
			{isSortOpen && (
				<SortModal isOpened={isSortOpen} closeModal={closeSortModal}>
					<SortSelect {...sortingFilters} onChange={handleFilter} />
				</SortModal>
			)}
			{isCommissionModalOpen && (
				<ConfirmModal
					title={t('sales.modal.commission.title')}
					isOpened={isCommissionModalOpen}
					closeModal={closeCommissionModal}
					confirm={requestCommissionMutation}>
					<p>{t('sales.modal.commission.text')}</p>
				</ConfirmModal>
			)}
			{isPayoutModalOpen && (
				<ConfirmModal
					title={t('sales.modal.payout.title')}
					isOpened={isPayoutModalOpen}
					closeModal={closePayoutModal}
					confirm={requestPayoutMutation}>
					<p>{t('sales.modal.payout.text')}</p>
				</ConfirmModal>
			)}
			{isExportModalOpen && (
				<ConfirmModal
					title={t('sales.modal.export.title')}
					isOpened={isExportModalOpen}
					closeModal={closeExportModal}
					confirm={handleExportModal}>
					<p>{t('sales.modal.export.text')}</p>
				</ConfirmModal>
			)}
			{isFormatModalOpen && (
				<FormatModal
					disableButton={chosenExportFormat === null}
					isOpened={isFormatModalOpen}
					closeModal={closeFormatModal}
					confirm={requestExport}
					isLoading={isExportLoading}
					onChange={handleFormatChange}
				/>
			)}
		</>
	)
}

export default SalesPage
