import { useTranslation } from 'libs'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Input from 'components/Ui/Input'
import Form from 'components/Ui/Form'
import { useFetchData } from 'hooks'
import { HistoryButtonWrapper } from 'components/Ui/Button/History'
import HistoryButton from 'components/Ui/Button/History'
import { HeaderWrapper, StyledHeader, Wrapper } from './styles'
import { MobileBackButton } from 'components/Ui/Button/Back'

const SalePage = () => {
	const { t } = useTranslation()
	const params = useParams()
	const { register, setValue } = useForm()

	useFetchData({
		name: 'fetchSale',
		endpoint: `/sales/${params.id}/`,
		options: {
			onSuccess: (res: any) => {
				const {
					created,
					commission_percentage,
					payout,
					product,
					receipt_reference,
					quantity,
					vat_percentage,
					vat,
					subtotal,
					discount,
					total,
					commission,
					account
				} = res.data

				setValue('created', created)
				setValue('commission_percentage', commission_percentage)
				setValue('payout', payout)
				setValue('product_sku', product.sku)
				setValue('receipt_reference', receipt_reference)
				setValue('product_name', product.name)
				setValue('unit_price', product.price.amount)
				setValue('quantity', quantity)
				setValue('vat_percentage', vat_percentage)
				setValue('vat_amount', vat.amount)
				setValue('subtotal', subtotal.amount)
				setValue('discount', discount.amount)
				setValue('total', total.amount)
				setValue('commission_amount', commission.amount)
				setValue('user', account.name)
			}
		}
	})

	return (
		<>
			<HeaderWrapper>
				<StyledHeader title={t('sale.title')} />
				<HistoryButtonWrapper>
					<MobileBackButton to="/sales" />
					<HistoryButton to={`/sales/${params.id}/history`} />
				</HistoryButtonWrapper>
			</HeaderWrapper>
			<Wrapper>
				<Form>
					<Input label={t('sale.form.user')} id="user" {...register('user')} disabled={true} />
					<Input
						label={t('sale.form.created')}
						id="created"
						{...register('created')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.ref')}
						id="ref"
						{...register('receipt_reference')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.product_sku')}
						id="product_sku"
						{...register('product_sku')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.product_name')}
						id="product_name"
						{...register('product_name')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.unit_price')}
						id="unit_price"
						{...register('unit_price')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.quantity')}
						id="quantity"
						{...register('quantity')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.vat_percent')}
						id="vat_percent"
						{...register('vat_percentage')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.vat_amount')}
						id="vat_amount"
						{...register('vat_amount')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.subtotal')}
						id="subtotal"
						{...register('subtotal')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.discount')}
						id="discount"
						{...register('discount')}
						disabled={true}
					/>
					<Input label={t('sale.form.total')} id="total" {...register('total')} disabled={true} />
					<Input
						label={t('sale.form.commission_percent')}
						id="commission_percent"
						{...register('commission_percentage')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.commission_amount')}
						id="commission_amount"
						{...register('commission_amount')}
						disabled={true}
					/>
					<Input
						label={t('sale.form.payout')}
						id="payout"
						{...register('payout')}
						disabled={true}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

export default SalePage
