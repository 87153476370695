import { useTranslation } from 'libs'
import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Controller, useForm } from 'react-hook-form'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Header from 'components/Header'
import Form from 'components/Ui/Form'
import { useFetchInfinite } from 'hooks'
import Select from 'components/Ui/Select'
import Input from 'components/Ui/Input'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import { useNavigate } from 'react-router-dom'
// import { boothValidationSchema } from 'schemas/validation'
import useSelect from 'hooks/useSelect'
import Checkbox from 'components/Ui/Checkbox'

const BoothAddPage = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const successMessage = useToast({ type: 'success', message: t('addBooth.form.success') })
	const failMessage = useToast({ type: 'error', message: t('addBooth.form.error') })
	const navigate = useNavigate()
	const boothValidationSchema = yup.object({
		number: yup
			.string()
			.matches(/^\d+$/, t('validation.addBooth.number.matches'))
			.required(t('validation.addBooth.number.required')),
		boothType: yup.object({
			value: yup.string().required(t('validation.addBooth.boothType.value.required'))
		}),
		is_active: yup.bool().required()
	})
	const {
		handleSubmit,
		control,
		register,
		setValue,
		trigger,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(boothValidationSchema)
	})
	const { handleSelectChange } = useSelect({ setValue, trigger })

	const { data: boothTypes, fetchNextPage } = useFetchInfinite({
		name: 'fetchBoothTypes',
		endpoint: '/booth-types/'
	})

	const { mutate: addBoothMutation, isLoading: isAddBoothLoading } = useMutation(
		(data) => {
			return axiosPrivate.post('/booths/', data)
		},
		{
			onSuccess: () => {
				successMessage()
				navigate('/booths')
			},
			onError: () => {
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		addBoothMutation({
			booth_type: data.boothType.value,
			number: +data.number,
			is_active: data.is_active
		} as any)
	}

	return (
		<>
			<Header title={t('addBooth.title')} />
			<Wrapper>
				<Form
					submitText={t('addBooth.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={isAddBoothLoading}>
					<Controller
						name="boothType"
						control={control}
						render={({ field }) => (
							<Select
								id="boothType"
								placeholder={t('filters.placeholder')}
								label={t('filters.boothType')}
								options={boothTypes?.pages
									.reduce((total: any, page: any) => [...total, ...page.results], [])
									.map((boothType: any) => ({ label: `${boothType.name}`, value: boothType.pk }))}
								{...field}
								error={(errors as any).boothType?.value}
								handleInfiniteScroll={fetchNextPage}
								required={true}
								onChange={(data: any) =>
									handleSelectChange({
										id: 'boothType',
										value: data?.value,
										label: data?.label
									})
								}
							/>
						)}
					/>
					<Input
						id="number"
						label={t('addBooth.form.number.label')}
						{...register('number')}
						placeholder={t('addBooth.form.number.placeholder')}
						icon={faHashtag}
						error={(errors as any).number}
						required={true}
					/>
					<Checkbox
						{...register('is_active')}
						label={t('addBooth.form.active.label')}
						desc={t('addBooth.form.active.desc')}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div`
	padding: 0 1rem;

	@media (min-width: ${theme`screens.lg`}) {
		padding: 0;
	}
`

export default BoothAddPage
