import { Link } from 'react-router-dom'
import { useTranslation } from 'libs'
import tw from 'twin.macro'

import { EllipsisText } from 'components/Ui'
import CardWrapper from 'components/Card/Wrapper'

import type { DashboardListProps } from '../types'

export const DashboardList = ({ title, list = [], emptyText }: DashboardListProps) => {
	const { t } = useTranslation()

	return (
		<StyledCardWrapper>
			<Title>{title}</Title>
			<List>
				{list.length ? (
					list.map((listItem) => (
						<ListItem key={listItem.id}>
							<EllipsisText>
								<ListItemText>{listItem.name}</ListItemText>
							</EllipsisText>
							<ListItemLink to={`/products/${listItem.id}`}>
								{t('dashboard.tiles.anchor')}
							</ListItemLink>
						</ListItem>
					))
				) : (
					<p>{emptyText}</p>
				)}
			</List>
		</StyledCardWrapper>
	)
}

const StyledCardWrapper = tw(CardWrapper)`p-4`

const Title = tw.h3`font-semibold text-base text-emperor mb-4`

const List = tw.div`grid lg:grid-cols-2 gap-x-8`

const ListItem = tw.div`flex justify-between items-center py-1`

const ListItemText = tw.span`text-sm text-dustyGray`

const ListItemLink = tw(Link)`text-sm text-blueRibbon`
