import { useTranslation } from 'libs'
import { Controller, useForm } from 'react-hook-form'
import { format, parseISO } from 'date-fns'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import PageWrapper from 'components/Ui/PageWrapper'
import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import Select from 'components/Ui/Select'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import HistoryButton from 'components/Ui/Button/History'
import { HeaderWrapper, StyledHeader, Row, StyledSelect } from './styles'
import { HistoryButtonWrapper } from 'components/Ui/Button/History'
import { useUserRoles } from 'hooks'
import FormSubtitle from 'components/Ui/Form/Subtitle'
import { MobileBackButton } from 'components/Ui/Button/Back'

const PayoutPage = () => {
	const { t } = useTranslation()
	const params = useParams()
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm()
	const axiosPrivate = useAxiosPrivate()
	const navigate = useNavigate()
	const successMessage = useToast({ type: 'success', message: t('payout.form.success') })
	const failMessage = useToast({ type: 'error', message: t('payout.form.error') })
	const { isUser } = useUserRoles()
	const queryClient = useQueryClient()

	const fetchCurrencies = () =>
		axiosPrivate.options('/settings/').then((res) => res?.data?.actions?.PUT?.currency?.choices)

	useFetchData({
		name: 'fetchPayout',
		endpoint: `/payouts/${params.id}/`,
		options: {
			onSuccess: (res: any) => {
				const { reference, tx_reference, created, account, payout_method, amount, status } =
					res.data

				setValue('reference', reference)
				setValue('tx_reference', tx_reference)
				setValue('created', format(parseISO(created), 'dd/MM/yyyy, hh:mm aa'))
				setValue('name', account?.name)
				setValue('email', account?.email)
				setValue('ssn', account?.ssn)
				setValue('bank-bank', payout_method[payout_method.kind].branch)
				setValue('bank-ledger', payout_method[payout_method.kind].ledger)
				setValue('bank-account', payout_method[payout_method.kind].account)
				setValue('amount', amount.amount)
				setValue('currency', { value: amount.currency, label: amount.currency })
				setValue('status', {
					value: status,
					label: status.charAt(0).toUpperCase() + status.slice(1)
				})
			}
		}
	})

	const { data: currencies } = useQuery('fetchCurrencies', fetchCurrencies)

	const { mutate: editStatusMutation, isLoading: loadingEditStatus } = useMutation(
		(data) => {
			return axiosPrivate.patch(`/payouts/${params.id}/`, data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchPayouts')
				successMessage()

				navigate('/payouts')
			},
			onError: () => {
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		const newStatus = {
			status: data?.status?.value,
			tx_reference: data?.tx_reference
		}
		editStatusMutation(newStatus as any)
	}

	const statusOptions = [
		{
			value: 'init',
			label: t('payout.form.status.options.init')
		},
		{
			value: 'requested',
			label: t('payout.form.status.options.requested')
		},
		{
			value: 'processing',
			label: t('payout.form.status.options.processing')
		},
		{
			value: 'paid',
			label: t('payout.form.status.options.paid')
		},
		{
			value: 'cancelled',
			label: t('payout.form.status.options.cancelled')
		}
	]

	return (
		<>
			<HeaderWrapper>
				<StyledHeader title={t('payout.title')} />
				<HistoryButtonWrapper>
					<MobileBackButton to="/payouts" />
					<HistoryButton to={`/payouts/${params.id}/history`} />
				</HistoryButtonWrapper>
			</HeaderWrapper>
			<PageWrapper>
				<Form
					submitText={t('payout.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					disabled={isUser}
					isLoading={loadingEditStatus}>
					<FormSubtitle title={t('payout.form.user.title')} hasBorder={false} />
					<Row>
						<Input
							id="reference"
							label={t('payout.form.reference')}
							{...register('reference')}
							error={(errors as any).reference}
							disabled={true}
						/>
						<Input
							id="created"
							label={t('payout.form.created')}
							{...register('created')}
							error={(errors as any).reference}
							disabled={true}
						/>
					</Row>
					<Row>
						<Input
							id="name"
							label={t('payout.form.name')}
							{...register('name')}
							error={(errors as any).reference}
							disabled={true}
						/>
						<Input
							id="email"
							label={t('payout.form.email')}
							{...register('email')}
							error={(errors as any).reference}
							disabled={true}
						/>
					</Row>
					<Row>
						<Input
							id="ssn"
							label={t('payout.form.ssn')}
							{...register('ssn')}
							error={(errors as any).reference}
							disabled={true}
						/>
					</Row>
					<Row>
						<Input
							id="bank-bank"
							label={t('payout.form.bank')}
							{...register('bank-bank')}
							error={(errors as any).reference}
							disabled={true}
						/>
						<Input
							id="bank-ledger"
							label={t('payout.form.bankledger')}
							{...register('bank-ledger')}
							error={(errors as any).reference}
							disabled={true}
						/>
						<Input
							id="bank-account"
							label={t('payout.form.bankaccount')}
							{...register('bank-account')}
							error={(errors as any).reference}
							disabled={true}
						/>
					</Row>
					<Row>
						<Controller
							name="currency"
							control={control}
							render={({ field }) => (
								<StyledSelect
									id="currency"
									placeholder={t('payout.form.currency.placeholder')}
									label={t('payout.form.currency.label')}
									options={currencies?.map((currency: any) => ({
										value: currency.value,
										label: currency.display_name
									}))}
									{...field}
									disabled={true}
								/>
							)}
						/>
						<Input
							id="amount"
							label={t('payout.form.amount')}
							{...register('amount')}
							error={(errors as any).reference}
							disabled={true}
						/>
					</Row>
					<Row>
						<Input
							id="tx_reference"
							label={t('payout.form.tx_reference')}
							{...register('tx_reference')}
							error={(errors as any).reference}
							disabled={isUser}
						/>
					</Row>
					<FormSubtitle title={t('payout.form.payment.title')} hasBorder={false} />
					<Controller
						name="status"
						control={control}
						render={({ field }) => (
							<Select
								id="status"
								placeholder={t('payout.form.status.placeholder')}
								label={t('payout.form.status.label')}
								options={statusOptions}
								{...field}
								disabled={isUser}
							/>
						)}
					/>
				</Form>
			</PageWrapper>
		</>
	)
}

export default PayoutPage
