import { useTranslation } from 'libs'
import { useMutation, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Header from 'components/Header'
import Form from 'components/Ui/Form'
import { useAxiosPrivate } from 'hooks'
import Input from 'components/Ui/Input'
import FormSubtitle from 'components/Ui/Form/Subtitle'
import { useToast } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { Wrapper } from './styles'

const UserAddPage = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const successMessage = useToast({ type: 'success', message: t('addUser.form.success') })
	const failMessage = useToast({ type: 'error', message: t('addUser.form.error') })
	const navigate = useNavigate()

	const addUserValidationSchema = yup.object({
		email: yup.string().email().required(t('validation.addUser.email.required')),
		password: yup
			.string()
			.min(7, t('validation.addUser.password.min'))
			.required(t('validation.addUser.email.required')),
		password2: yup
			.string()
			.oneOf([yup.ref('password'), null], t('validation.addUser.password2.oneOf'))
			.required(t('validation.addUser.password2.required'))
	})

	const {
		handleSubmit,
		register,
		setError,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(addUserValidationSchema)
	})

	const { mutate: createUser, isLoading: createUserLoading } = useMutation(
		(data) => {
			return axiosPrivate.post('/accounts/', data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchUsers')
				successMessage()

				navigate('/users')
			},
			onError: (res: any) => {
				if (res?.response?.data) {
					for (const [key, value] of Object.entries(res.response.data)) {
						setError(key, { message: value as string })
					}
				}

				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		createUser(data)
	}

	return (
		<>
			<Header title={t('addUser.title')} />
			<Wrapper>
				<Form
					submitText={t('addUser.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={createUserLoading}>
					<FormSubtitle title={t('addUser.form.user.title')} hasBorder={false} />
					<Input
						id="email"
						label={t('addUser.form.email')}
						{...register('email')}
						error={(errors as any).email}
						required={true}
					/>
					<Input
						id="password"
						type="password"
						label={t('addUser.form.password')}
						{...register('password')}
						error={(errors as any).password}
						required={true}
					/>
					<Input
						id="password2"
						type="password"
						label={t('addUser.form.confirmPassword')}
						{...register('password2')}
						error={(errors as any).password2}
						required={true}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

export default UserAddPage
