import { format } from 'date-fns'
import { useTranslation } from 'libs'
import { enGB, is } from 'date-fns/locale'
import { useNavigate } from 'react-router-dom'

import EllipsisMenu from 'components/Ui/EllipsisMenu'
import Status from 'components/Ui/Status'
import { Wrapper, Row, Mobile } from './styles'
import { IOpeningHour } from './types'

const OpeningHour = ({
	date,
	is_open,
	is_bookable,
	counts_towards_duration,
	is_holiday,
	reason
}: IOpeningHour) => {
	const { t, i18n } = useTranslation()
	const { language } = i18n
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/opening-hours/${date}`)

	const ellipsisMenuOptions = [
		{
			label: t('openingHours.openingHour.ellipsisMenu.edit'),
			action: redirectToEditPage
		}
	]

	return (
		<Wrapper>
			<Row>
				<Mobile>Date: </Mobile>
				<span>{date}</span>
			</Row>
			<Row>
				<Mobile>Open: </Mobile>
				<Status active={is_open} />
			</Row>
			<Row>
				<Mobile>Bookable: </Mobile>
				<Status active={is_bookable} />
			</Row>
			<Row>
				<Mobile>Counts towards duration: </Mobile>
				<span>
					<Status active={counts_towards_duration} />
				</span>
			</Row>
			<Row>
				<Mobile>Weekday: </Mobile>
				<span>{format(new Date(date), 'EEEE', { locale: language === 'is' ? is : enGB })}</span>
			</Row>
			<Row>
				<Mobile>Holiday: </Mobile>
				<span>{is_holiday}</span>
			</Row>
			<Row>
				<Mobile>Reason: </Mobile>
				<span>{reason}</span>
			</Row>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default OpeningHour
