import { useState } from 'react'
import { useTranslation } from 'libs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Header from 'components/Header'
import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import Select from 'components/Ui/Select'
import { useFetchInfinite } from 'hooks'
import Checkbox from 'components/Ui/Checkbox'
import { useAxiosPrivate } from 'hooks'
import { Wrapper, Row } from './styles'
import { useToast } from 'hooks'
import ColorPicker from 'components/ColorPicker'
// import { addRentalValidationSchema } from 'schemas/validation'

const RentalAddPage = () => {
	const { t } = useTranslation()
	const addRentalValidationSchema = yup.object({
		duration: yup.string().required(t('validation.addRental.duration.required')),
		price: yup.object({
			amount: yup.string().required(t('validation.addRental.price.amount.required'))
		}),
		original_price: yup.object({
			amount: yup.string().required(t('validation.addRental.originalPrice.amount.required'))
		})
	})
	const {
		handleSubmit,
		control,
		register,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(addRentalValidationSchema)
	})
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	const defaultColor = '#FFFFFF'
	const [rentalColor, setRentalColor] = useState(defaultColor)

	const { data: boothTypes, fetchNextPage } = useFetchInfinite({
		name: 'fetchBoothTypes',
		endpoint: '/booth-types/'
	})
	const handleRentalColorChange = (data: any) => {
		const { hex } = data

		setRentalColor(hex)
	}

	const successMessage = useToast({ type: 'success', message: t('addRentals.form.success') })
	const failMessage = useToast({ type: 'error', message: t('addRentals.form.error') })

	const fetchCurrencies = () =>
		axiosPrivate.options('/settings/').then((res) => res?.data?.actions?.PUT?.currency?.choices)

	const { data: currencies } = useQuery('fetchCurrencies', fetchCurrencies)

	const { mutate: createRental, isLoading: createRentalLoading } = useMutation(
		(data) => {
			return axiosPrivate.post('/rentals/', data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchRentals')
				successMessage()

				navigate('/rentals')
			},
			onError: () => {
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		const newRental = {
			...data,
			price: {
				amount: data.price.amount,
				currency: data.price.currency.value
			},
			original_price: {
				amount: data.original_price.amount,
				currency: data.price.currency.value
			},
			booth_type: data.booth_type?.value,
			color: rentalColor
		}

		createRental(newRental)
	}

	return (
		<>
			<Header title={t('addRentals.title')} />
			<Wrapper>
				<Form
					submitText={t('addRentals.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={createRentalLoading}>
					<Row>
						{/* <Controller
							name="store"
							control={control}
							render={({ field }) => (
								<StyledSelect
									id="store"
									placeholder={t('addRentals.form.store.placeholder')}
									label={t('addRentals.form.store.label')}
									options={[
										{
											value: '',
											label: storeCtx?.storeSettings?.name
										}
									]}
									{...field}
									disabled={true}
								/>
							)}
						/> */}
						<Controller
							name="booth_type"
							control={control}
							render={({ field }) => (
								<Select
									id="booth-type"
									placeholder={t('filters.placeholder')}
									label={t('filters.boothType')}
									options={boothTypes?.pages
										.reduce((total: any, page: any) => [...total, ...page.results], [])
										.map((boothType: any) => ({ label: `${boothType.name}`, value: boothType.pk }))}
									{...field}
									handleInfiniteScroll={fetchNextPage}
								/>
							)}
						/>
					</Row>
					<Row>
						<Input
							id="duration"
							label={t('addRentals.form.duration')}
							type="number"
							step="1"
							{...register('duration')}
							error={(errors as any).duration}
							required={true}
						/>
						<Input
							id="commission"
							label={t('addRentals.form.commission')}
							type="number"
							step="0.01"
							{...register('commission')}
						/>
					</Row>
					<Row>
						<Controller
							name="price.currency"
							control={control}
							render={({ field }) => (
								<Select
									id="currency"
									placeholder={t('addRentals.form.currency.placeholder')}
									label={t('addRentals.form.currency.label')}
									options={currencies?.map((currency: any) => ({
										value: currency.value,
										label: currency.display_name
									}))}
									{...field}
								/>
							)}
						/>
						<Input
							id="price"
							label={t('addRentals.form.price')}
							{...register('price.amount')}
							error={(errors as any).price?.amount}
							required={true}
						/>
						<Input
							id="originalPrice"
							label={t('addRentals.form.originalPrice')}
							{...register('original_price.amount')}
							error={(errors as any).original_price?.amount}
							required={true}
						/>
					</Row>
					<Checkbox
						{...register('is_active')}
						label={t('addRentals.form.active.label')}
						desc={t('addRentals.form.active.desc')}
					/>
					<ColorPicker
						label={t('addRentals.form.rentalColor.label')}
						color={rentalColor}
						onChange={handleRentalColorChange}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

export default RentalAddPage
