import { useTranslation } from 'libs'
import styled from 'styled-components'

import Spinner from 'components/Ui/Spinner'

interface IEmptyTable {
	isLoading?: boolean
}

const EmptyTable = ({ isLoading }: IEmptyTable) => {
	const { t } = useTranslation()

	return <Wrapper>{isLoading ? <Spinner /> : <Text>{t('table.empty')}</Text>}</Wrapper>
}

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	padding: 50px 0;
`

const Text = styled.span`
	font-size: 1.5rem;
	font-weight: 600;
`

export default EmptyTable
