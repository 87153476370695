import { useState, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation, format } from 'libs'
import { useMutation, useQueryClient } from 'react-query'

import FiltersWrapper from 'components/FiltersWrapper'
import Filters from 'components/Filters'
import MobileFilters from 'components/Filters/mobile'
import Booking from 'components/Booking'
import FiltersModal from 'components/Modals/filters'
import SortModal from 'components/Modals/sort'
import {
	useFetchData,
	usePagination,
	useSearch,
	useFilter,
	useModal,
	useAxiosPrivate,
	useToast,
	useDevice,
	useUserRoles,
	useTableSelect,
	useFetchInfinite
} from 'hooks'
import EmptyTable from 'components/Ui/Table/Empty'
import DatePickerInput from 'components/Ui/Datepicker'
import ConfirmModal from 'components/Modals/confirm'
import { IActionFilter } from 'interfaces/filters.interface'
import FormatModal from 'components/Modals/format'
import { defaultConfig } from 'config/default'
import { IOption } from 'interfaces/select.interface'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import { downloadAs } from 'utils/downloadFile'
import SortSelect from 'components/Ui/Select/Sort'
import Select from 'components/Ui/Select'
import {
	StyledSearchInput,
	StyledDatePickerInput,
	DesktopSortSelect,
	StyledTable,
	FiltersModalContent,
	ModalFilters,
	StyledTable2,
	HeaderContainer,
	DesktopCtaContainer,
	StyledHeader,
	MobileCtaContainer,
	CtaContainer,
	DesktopSelect
} from './styles'
import { TableWrapper, customTableStyles, FiltersContainer } from 'styles/general'
import CtaLink from 'components/Ui/CtaLink'
import { AuthContext } from 'store/AuthContext'
import { useActionFilters, useColumns, useFilters, useSortingFilters } from './hooks'

const BookingsPage = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handlePagination } = usePagination(setQuery)
	const { handleSearch } = useSearch({ name: 'search', cb: setQuery })
	const { handleFilter } = useFilter(setQuery)
	const [start, setStart] = useState('')
	const [end, setEnd] = useState('')
	const [checkedBookings, setCheckedBookings] = useState<number[]>([])
	const [chosenBooking, setChosenBooking] = useState<number | null>(null)
	const axiosPrivate = useAxiosPrivate()
	const [chosenExportFormat, setChosenExportFormat] = useState<number | string | null>(null)
	const queryClient = useQueryClient()
	const { isMobileOrTablet } = useDevice()
	const [bookingsData, setBookingsData] = useState({ nodes: [] })
	const { isUser, isStaff } = useUserRoles()
	const { select } = useTableSelect(bookingsData, onSelectChange)
	const { auth } = useContext(AuthContext)
	const actionFilters = useActionFilters(checkedBookings.length == 0)
	const sortingFilters = useSortingFilters()
	const filters = useFilters()
	const columns = useColumns()

	const {
		isOpen: isFiltersOpen,
		openModal: openFiltersModal,
		closeModal: closeFiltersModal
	} = useModal()
	const { isOpen: isSortOpen, openModal: openSortModal, closeModal: closeSortModal } = useModal()
	const {
		isOpen: isBulkSmsReminderOpen,
		openModal: openBulkSmsReminderModal,
		closeModal: closeBulkSmsReminderModal
	} = useModal()
	const {
		isOpen: isSmsReminderOpen,
		openModal: openSmsReminderModal,
		closeModal: closeSmsReminderModal
	} = useModal()
	const {
		isOpen: isBulkWelcomeEmailOpen,
		openModal: openBulkWelcomeEmailModal,
		closeModal: closeBulkWelcomeEmailModal
	} = useModal()
	const {
		isOpen: isWelcomeEmailOpen,
		openModal: openWelcomeEmailModal,
		closeModal: closeWelcomeEmailModal
	} = useModal()
	const {
		isOpen: isBulkEmailReminderOpen,
		openModal: openBulkEmailReminderModal,
		closeModal: closeBulkEmailReminderModal
	} = useModal()
	const {
		isOpen: isEmailReminderOpen,
		openModal: openEmailReminderModal,
		closeModal: closeEmailReminderModal
	} = useModal()
	const {
		isOpen: isExportModalOpen,
		openModal: openExportModal,
		closeModal: closeExportModal
	} = useModal()
	const {
		isOpen: isFormatModalOpen,
		openModal: openFormatModal,
		closeModal: closeFormatModal
	} = useModal()
	const {
		isOpen: isMultipleDeleteModalOpen,
		openModal: openMultipleDeleteModal,
		closeModal: closeMultipleDeleteModal
	} = useModal()

	const smsReminderSuccess = useToast({
		type: 'success',
		message: t('bookings.modal.smsReminder.success')
	})
	const smsReminderFail = useToast({
		type: 'error',
		message: t('bookings.modal.smsReminder.error')
	})

	const welcomeEmailSuccess = useToast({
		type: 'success',
		message: t('bookings.modal.welcomeEmail.success')
	})
	const welcomeEmailFail = useToast({
		type: 'error',
		message: t('bookings.modal.welcomeEmail.error')
	})

	const emailReminderSuccess = useToast({
		type: 'success',
		message: t('bookings.modal.emailReminder.success')
	})
	const emailReminderFail = useToast({
		type: 'error',
		message: t('bookings.modal.emailReminder.error')
	})

	const exportSuccess = useToast({
		type: 'success',
		message: t('bookings.modal.export.success')
	})
	const exportFail = useToast({
		type: 'error',
		message: t('bookings.modal.export.error')
	})

	const successMessage = useToast({
		type: 'success',
		message: t('bookings.modal.delete.success')
	})
	const failMessage = useToast({
		type: 'error',
		message: t('bookings.modal.delete.error')
	})

	const { data: bookings, isFetching: bookingsLoading } = useFetchData({
		name: 'fetchBookings',
		query,
		endpoint: '/bookings/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setBookingsData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const {
		data: booths,
		isLoading: loadingBooths,
		fetchNextPage: fetchNextBooths
	} = useFetchInfinite({
		name: 'fetchBooths',
		endpoint: '/booths/',
		options: {
			enabled: !!auth.accessToken
		}
	})

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value
		switch (action) {
			case 'smsReminder':
				openBulkSmsReminderModal()
				break
			case 'emailWelcome':
				openBulkWelcomeEmailModal()
				break
			case 'emailReminder':
				openBulkEmailReminderModal()
				break
			case 'export':
				openExportModal()
				break
			case 'delete':
				openMultipleDeleteModal()
				break
		}
	}

	const { mutate: smsReminderBulkMutation } = useMutation(
		() => {
			return axiosPrivate.post('/bookings/send_end_reminder_sms/', { ids: checkedBookings })
		},
		{
			onSuccess: () => {
				smsReminderSuccess()
			},
			onError: () => {
				smsReminderFail()
			},
			onSettled: () => {
				closeBulkSmsReminderModal()
			}
		}
	)

	const { mutate: smsReminderMutation } = useMutation(
		() => {
			return axiosPrivate.post('/bookings/send_end_reminder_sms/', { ids: [chosenBooking] })
		},
		{
			onSuccess: () => {
				smsReminderSuccess()
			},
			onError: () => {
				smsReminderFail()
			},
			onSettled: () => {
				closeSmsReminderModal()
			}
		}
	)

	const { mutate: welcomeEmailBulkMutation } = useMutation(
		() => {
			return axiosPrivate.post('/bookings/send_welcome_email/', { ids: checkedBookings })
		},
		{
			onSuccess: () => {
				welcomeEmailSuccess()
			},
			onError: () => {
				welcomeEmailFail()
			},
			onSettled: () => {
				closeBulkWelcomeEmailModal()
			}
		}
	)

	const { mutate: welcomeEmailMutation } = useMutation(
		() => {
			return axiosPrivate.post('/bookings/send_welcome_email/', { ids: [chosenBooking] })
		},
		{
			onSuccess: () => {
				welcomeEmailSuccess()
			},
			onError: () => {
				welcomeEmailFail()
			},
			onSettled: () => {
				closeWelcomeEmailModal()
			}
		}
	)

	const { mutate: emailReminderBulkMutation } = useMutation(
		() => {
			return axiosPrivate.post('/bookings/send_end_reminder_email/', { ids: checkedBookings })
		},
		{
			onSuccess: () => {
				emailReminderSuccess()
			},
			onError: () => {
				emailReminderFail()
			},
			onSettled: () => {
				closeBulkEmailReminderModal()
			}
		}
	)

	const { mutate: emailReminderMutation } = useMutation(
		() => {
			return axiosPrivate.post('/bookings/send_end_reminder_email/', { ids: [chosenBooking] })
		},
		{
			onSuccess: () => {
				emailReminderSuccess()
			},
			onError: () => {
				emailReminderFail()
			},
			onSettled: () => {
				closeEmailReminderModal()
			}
		}
	)

	const { mutate: exportBulkMutation, isLoading: isExportLoading } = useMutation(
		() => {
			return axiosPrivate.post(
				'/bookings/export/',
				{ format: chosenExportFormat, ids: checkedBookings },
				{ responseType: 'arraybuffer' }
			)
		},
		{
			onSuccess: (res) => {
				const format = defaultConfig.exportFormatOptions
					.find((format) => format.value === chosenExportFormat)
					?.label.toLowerCase()

				if (format) {
					downloadAs({ file: res.data, name: 'bookings-export', format })
				}

				setCheckedBookings([])
				exportSuccess()
			},
			onError: () => {
				exportFail()
			},
			onSettled: () => {
				closeFormatModal()
			}
		}
	)

	const { mutate: bookingsDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.post('/bookings/bulk_delete/', { ids: checkedBookings })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchBookings')
				successMessage()
			},
			onError: () => {
				failMessage()
			},
			onSettled: () => {
				setCheckedBookings([])
				closeMultipleDeleteModal()
			}
		}
	)

	const bulkSmsReminder = () => {
		smsReminderBulkMutation()
	}

	const smsReminder = () => {
		smsReminderMutation()
	}

	const bulkWelcomeEmail = () => {
		welcomeEmailBulkMutation()
	}

	const welcomeEmail = () => {
		welcomeEmailMutation()
	}

	const bulkEmailReminder = () => {
		emailReminderBulkMutation()
	}

	const emailReminder = () => {
		emailReminderMutation()
	}

	const handleExportModal = () => {
		closeExportModal()
		openFormatModal()
	}

	const requestExport = () => {
		exportBulkMutation()
	}

	const handleFormatChange = (data: IOption) => {
		data?.value?.toString() ? setChosenExportFormat(data.value) : setChosenExportFormat(null)
	}

	const handleDateFilter = (filter: string, date: any) => {
		if (filter === 'start' && !date) {
			setStart('')
			handleFilter({ id: 'start_date', value: null })
		} else if (filter === 'end' && !date) {
			setEnd('')
			handleFilter({ id: '_end_date', value: null })
		}

		if (filter === 'start' && date) {
			setStart(date)
			handleFilter({ id: 'start_date', value: format(date, 'yyyy-MM-dd') })
		} else if (filter === 'end' && date) {
			setEnd(date)
			handleFilter({ id: '_end_date', value: format(date, 'yyyy-MM-dd') })
		}
	}

	function onSelectChange(action: any, state: any) {
		setCheckedBookings(state.ids)
	}

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns:  40px repeat(${columns.length}, 1fr);
	`
	}

	const handleSmsReminder = (id: number) => {
		setChosenBooking(id)
		openSmsReminderModal()
	}

	const handleEmailWelcome = (id: number) => {
		setChosenBooking(id)
		openWelcomeEmailModal()
	}

	const handleEmailReminder = (id: number) => {
		setChosenBooking(id)
		openEmailReminderModal()
	}

	return (
		<>
			<HeaderContainer>
				<StyledHeader title={t('bookings.title')} handleSearch={handleSearch} />
				{isStaff && (
					<DesktopCtaContainer>
						<CtaLink to="/bookings/add" text={t('bookings.cta')} />
					</DesktopCtaContainer>
				)}
			</HeaderContainer>
			<FiltersWrapper>
				<FiltersContainer>
					<StyledSearchInput
						label={t('bookings.filters.search.label')}
						placeholder={t('bookings.filters.search.placeholder')}
						onChange={handleSearch}
					/>
					<Filters filters={filters} handleFilter={handleFilter} />
					<DesktopSelect
						isLoading={loadingBooths}
						key="area_id"
						id="area_id"
						placeholder={t('filters.placeholder')}
						label={t('filters.booth')}
						options={
							!loadingBooths && booths != null && Array.isArray(booths?.pages)
								? booths?.pages
										.reduce((total: any, page: any) => {
											return [...total, ...page.results]
										}, [])
										.map((booth: any) => {
											return {
												label: `#${booth.number} ${booth?.booth_type_name ?? ''}`,
												value: booth.id
											}
										})
								: []
						}
						handleInfiniteScroll={fetchNextBooths}
						onChange={(data: any) => handleFilter({ id: 'area_id', value: data?.value || null })}
					/>
					<StyledDatePickerInput
						label={t('bookings.filters.start.label')}
						onChange={(date) => handleDateFilter('start', date)}
						selected={start}
						dateFormat="dd.MM.yyyy"
						isClearable
					/>
					<StyledDatePickerInput
						label={t('bookings.filters.end.label')}
						onChange={(date) => handleDateFilter('end', date)}
						selected={end}
						dateFormat="dd.MM.yyyy"
						isClearable
					/>
					<Filters
						filters={actionFilters}
						disableActions={false}
						handleFilter={handleActionFilter}
					/>
					{!isUser && (
						<>
							<CtaContainer>
								<CtaLink to="/bookings/calendar" text={t('bookings.calendar.btn')} icon={false} />
							</CtaContainer>
							<MobileCtaContainer>
								<CtaLink to="/bookings/add" text={t('bookings.cta')} />
							</MobileCtaContainer>
						</>
					)}
				</FiltersContainer>
				<DesktopSortSelect {...sortingFilters} onChange={handleFilter} />
				<MobileFilters openFilters={openFiltersModal} openSort={openSortModal} />
			</FiltersWrapper>
			{bookingsData.nodes != null &&
			Array.isArray(bookingsData.nodes) &&
			bookingsData.nodes.length ? (
				<TableWrapper>
					{isMobileOrTablet ? (
						<StyledTable
							isLoading={bookingsLoading}
							headers={[]}
							prev={bookings?.data?.previous}
							next={bookings?.data?.next}
							onClick={handlePagination}>
							{bookings?.data?.results.map((booking: any) => (
								<Booking
									key={booking.id}
									{...booking}
									handleSmsReminder={handleSmsReminder}
									handleEmailWelcome={handleEmailWelcome}
									handleEmailReminder={handleEmailReminder}
								/>
							))}
						</StyledTable>
					) : (
						<StyledTable2
							columns={columns}
							data={bookingsData}
							isLoading={bookingsLoading}
							select={select}
							prev={bookings?.data?.previous}
							next={bookings?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}

					{isBulkSmsReminderOpen && (
						<ConfirmModal
							title={t('bookings.modal.bulkSmsReminder.title')}
							isOpened={isBulkSmsReminderOpen}
							closeModal={closeBulkSmsReminderModal}
							confirm={bulkSmsReminder}>
							<p>{t('bookings.modal.bulkSmsReminder.text')}</p>
						</ConfirmModal>
					)}
					{isSmsReminderOpen && (
						<ConfirmModal
							title={t('bookings.modal.smsReminder.title')}
							isOpened={isSmsReminderOpen}
							closeModal={closeSmsReminderModal}
							confirm={smsReminder}>
							<p>{t('bookings.modal.smsReminder.text')}</p>
						</ConfirmModal>
					)}
					{isBulkWelcomeEmailOpen && (
						<ConfirmModal
							title={t('bookings.modal.bulkWelcomeEmail.title')}
							isOpened={isBulkWelcomeEmailOpen}
							closeModal={closeBulkWelcomeEmailModal}
							confirm={bulkWelcomeEmail}>
							<p>{t('bookings.modal.bulkWelcomeEmail.text')}</p>
						</ConfirmModal>
					)}
					{isWelcomeEmailOpen && (
						<ConfirmModal
							title={t('bookings.modal.welcomeEmail.title')}
							isOpened={isWelcomeEmailOpen}
							closeModal={closeWelcomeEmailModal}
							confirm={welcomeEmail}>
							<p>{t('bookings.modal.welcomeEmail.text')}</p>
						</ConfirmModal>
					)}
					{isBulkEmailReminderOpen && (
						<ConfirmModal
							title={t('bookings.modal.bulkEmailReminder.title')}
							isOpened={isBulkEmailReminderOpen}
							closeModal={closeBulkEmailReminderModal}
							confirm={bulkEmailReminder}>
							<p>{t('bookings.modal.bulkEmailReminder.text')}</p>
						</ConfirmModal>
					)}
					{isEmailReminderOpen && (
						<ConfirmModal
							title={t('bookings.modal.emailReminder.title')}
							isOpened={isEmailReminderOpen}
							closeModal={closeEmailReminderModal}
							confirm={emailReminder}>
							<p>{t('bookings.modal.emailReminder.text')}</p>
						</ConfirmModal>
					)}
					{isExportModalOpen && (
						<ConfirmModal
							title={t('bookings.modal.export.title')}
							isOpened={isExportModalOpen}
							closeModal={closeExportModal}
							confirm={handleExportModal}>
							<p>{t('bookings.modal.export.text')}</p>
						</ConfirmModal>
					)}
					{isFormatModalOpen && (
						<FormatModal
							disableButton={chosenExportFormat === null}
							isOpened={isFormatModalOpen}
							closeModal={closeFormatModal}
							confirm={requestExport}
							isLoading={isExportLoading}
							onChange={handleFormatChange}
						/>
					)}
					{isMultipleDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('bookings.modal.delete.title')}
							isOpened={isMultipleDeleteModalOpen}
							closeModal={closeMultipleDeleteModal}
							confirm={bookingsDeleteMutation}>
							<p>{t('bookings.modal.delete.text', { count: checkedBookings.length })}</p>
						</DeleteConfirmModal>
					)}
				</TableWrapper>
			) : (
				<EmptyTable isLoading={bookingsLoading} />
			)}
			{isFiltersOpen && (
				<FiltersModal isOpened={isFiltersOpen} closeModal={closeFiltersModal}>
					<FiltersModalContent>
						<ModalFilters filters={filters} handleFilter={handleFilter} />
						<Select
							isLoading={loadingBooths}
							key="area_id"
							id="area_id"
							placeholder={t('filters.placeholder')}
							label={t('filters.booth')}
							options={
								!loadingBooths && booths != null && Array.isArray(booths?.pages)
									? booths?.pages
											.reduce((total: any, page: any) => {
												return [...total, ...page.results]
											}, [])
											.map((booth: any) => {
												return {
													label: `#${booth.number} ${booth?.booth_type_name ?? ''}`,
													value: booth.id
												}
											})
									: []
							}
							handleInfiniteScroll={fetchNextBooths}
							onChange={(data: any) => handleFilter({ id: 'area_id', value: data?.value || null })}
						/>
						<DatePickerInput
							label={t('bookings.filters.start.label')}
							onChange={(date) => handleDateFilter('start', date)}
							selected={start}
							dateFormat="dd.MM.yyyy"
							isClearable
						/>
						<DatePickerInput
							label={t('bookings.filters.end.label')}
							onChange={(date) => handleDateFilter('end', date)}
							selected={end}
							dateFormat="dd.MM.yyyy"
							isClearable
						/>
					</FiltersModalContent>
				</FiltersModal>
			)}
			{isSortOpen && (
				<SortModal isOpened={isSortOpen} closeModal={closeSortModal}>
					<SortSelect {...sortingFilters} onChange={handleFilter} />
				</SortModal>
			)}
		</>
	)
}

export default BookingsPage
