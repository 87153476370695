import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'libs'

import EllipsisMenu from 'components/Ui/EllipsisMenu'
import { Wrapper, Mobile } from './styles'

import type { ICoupon } from './types'

const Coupon = ({ id, code, price_discount, commission_discount, openDeleteModal }: ICoupon) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/coupons/${id}`)

	const ellipsisMenuOptions = [
		{
			label: t('coupons.coupon.ellipsisMenu.edit'),
			action: redirectToEditPage
		},
		{
			label: t('coupons.coupon.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<Wrapper>
			<span>
				{code ? (
					<>
						<Mobile>{t('coupons.coupon.name')}</Mobile>{' '}
						<NavLink to={`/coupons/${id}`}>{code}</NavLink>
					</>
				) : (
					''
				)}
			</span>
			<span>
				{price_discount ? (
					<>
						<Mobile>{t('coupons.coupon.price_discount')}</Mobile> {price_discount.value}
						{price_discount.is_percentage && '%'}
					</>
				) : (
					''
				)}
			</span>
			<span>
				{commission_discount ? (
					<>
						<Mobile>{t('coupons.coupon.commission_discount')}</Mobile> {commission_discount.value}
						{commission_discount.is_percentage && '%'}
					</>
				) : (
					''
				)}
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default Coupon
