import { useMutation, useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'libs'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import { useForm } from 'react-hook-form'
import { HeaderWrapper, StyledHeader, Wrapper } from './styles'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import { useFetchData } from 'hooks'
import { HistoryButtonWrapper } from 'components/Ui/Button/History'
import HistoryButton from 'components/Ui/Button/History'
import { useUserRoles } from 'hooks'
import { MobileBackButton } from 'components/Ui/Button/Back'
// import { addTagValidationSchema } from 'schemas/validation'

const TagPage = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const successMessage = useToast({ type: 'success', message: t('tag.form.success') })
	const failMessage = useToast({ type: 'error', message: t('tag.form.error') })
	const params = useParams()
	const { isUser } = useUserRoles()

	const addTagValidationSchema = yup.object({
		name: yup.string().required(t('validation.addTag.name.required'))
	})

	const {
		handleSubmit,
		register,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(addTagValidationSchema)
	})

	useFetchData({
		name: 'fetchTag',
		endpoint: `/tags/${params.id}/`,
		options: {
			onSuccess: (res: any) => {
				setValue('name', res.data.name)
			}
		}
	})

	const { mutate: editTag, isLoading: editTagLoading } = useMutation(
		(data) => {
			return axiosPrivate.patch(`/tags/${params.id}/`, data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchTags')
				successMessage()
				navigate('/tags')
			},
			onError: () => {
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		const newData = {
			...data,
			slug: data.name.toLowerCase().replace(/\s+/g, '-')
		}

		editTag(newData)
	}

	return (
		<>
			<HeaderWrapper>
				<StyledHeader title={t('tag.title')} />
				<HistoryButtonWrapper>
					<MobileBackButton to="/tags" />
					<HistoryButton to={`/tags/${params.id}/history`} />
				</HistoryButtonWrapper>
			</HeaderWrapper>
			<Wrapper>
				<Form
					submitText={t('tag.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={editTagLoading}
					disabled={isUser}>
					<Input
						id="tag"
						label={t('tag.form.tag')}
						{...register('name')}
						error={(errors as any).name}
						disabled={isUser}
						required={true}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

export default TagPage
