import { useTranslation } from 'libs'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import { StyledHeader } from './styles'
import { TableWrapper } from 'styles/general'

const BoothTypeHistory = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: boothTypeHistory, isLoading } = useFetchData({
		name: 'fetchBoothTypeHistory',
		endpoint: `/booth-types/${params.id}/history/`
	})

	return (
		<>
			<StyledHeader title={t('boothTypeHistory.title')} />
			<TableWrapper>
				<HistoryTable results={boothTypeHistory?.data} isLoading={isLoading} />
			</TableWrapper>
		</>
	)
}

export default BoothTypeHistory
