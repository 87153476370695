import { useTranslation } from 'libs'

import { Wrapper, Content, Title, Text, StyledCtaLink } from './styles'

const ErrorPage = () => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Content>
				<Title>404</Title>
				<Text>{t('404.text')}</Text>
				<StyledCtaLink to="/" text={t('404.cta')} icon={false} />
			</Content>
		</Wrapper>
	)
}

export default ErrorPage
