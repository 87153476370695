import { useContext } from 'react'
import { useTranslation } from 'libs'

import { DashboardList } from './'
import { DashboardContext } from 'store'
import { Lists } from '../styles'
import { RecentlyAddedProduct, RecentlySoldProduct } from '../types'

export const DashboardLists = () => {
	const dashboardCtx = useContext(DashboardContext)

	const { t } = useTranslation()

	return (
		<Lists>
			<DashboardList
				title={t('dashboard.tiles.tile1.title')}
				emptyText={t('dashboard.tiles.tile1.empty')}
				list={dashboardCtx?.dashboard?.data?.recently_added_products as RecentlyAddedProduct[]}
			/>
			<DashboardList
				title={t('dashboard.tiles.tile2.title')}
				emptyText={t('dashboard.tiles.tile2.empty')}
				list={dashboardCtx?.dashboard?.data?.recently_sold_products as RecentlySoldProduct[]}
			/>
		</Lists>
	)
}
