import { useState, useContext } from 'react'
import { useTranslation } from 'libs'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { Column } from '@table-library/react-table-library/compact'
import { format, parseISO } from 'date-fns'
import { useFetchData } from 'hooks'
import { usePagination } from 'hooks'
import Coupon from 'components/Coupon'
import EmptyTable from 'components/Ui/Table/Empty'
import { useMutation, useQueryClient } from 'react-query'
import { useAxiosPrivate } from 'hooks'
import { useModal } from 'hooks'
import { useToast } from 'hooks'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import Filters from 'components/Filters'
import { IActionFilter } from 'interfaces/filters.interface'
import { useSearch } from 'hooks'
import CtaLink from 'components/Ui/CtaLink'
import {
	StyledFiltersWrapper,
	StyledTable,
	StyledHeader,
	HeaderContainer,
	DesktopCtaContainer,
	MobileCtaContainer,
	StyledTable2,
	StyledSearchInput,
	FiltersContainer
} from './styles'
import { useUserRoles } from 'hooks'
import { customTableStyles, TableWrapper } from 'styles/general'
import { useDevice } from 'hooks'
import ContextMenu from 'components/Ui/ContextMenu'
import { useTableSelect } from 'hooks'
import { AuthContext } from 'store/AuthContext'
import { theme } from 'twin.macro'

const CouponsPage = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handlePagination } = usePagination(setQuery)
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const [idToBeRemoves, setIdToBeRemoved] = useState<number | null>(null)
	const [checkedCoupons, setCheckedCoupons] = useState<number[]>([])
	const { isUser } = useUserRoles()
	const [couponsData, setCouponsData] = useState({ nodes: [] })
	const { select } = useTableSelect(couponsData, onSelectChange)
	const { isMobileOrTablet } = useDevice()
	const navigate = useNavigate()
	const { handleSearch } = useSearch({ name: 'search', cb: setQuery })
	const { auth } = useContext(AuthContext)

	const successMessage = useToast({
		type: 'success',
		message: t('coupons.modal.delete.success')
	})
	const failMessage = useToast({
		type: 'error',
		message: t('coupons.modal.delete.error')
	})

	const bulkDeleteSuccessMsg = useToast({
		type: 'success',
		message: t('coupons.modal.bulkDelete.success')
	})
	const bulkDeleteFailMsg = useToast({
		type: 'error',
		message: t('coupons.modal.bulkDelete.error')
	})

	const { data: coupons, isFetching: loadingCoupons } = useFetchData({
		name: 'fetchCoupons',
		endpoint: '/coupons/',
		query,
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setCouponsData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const {
		isOpen: isDeleteModalOpen,
		openModal: openDeleteModal,
		closeModal: closeDeleteModal
	} = useModal()
	const {
		isOpen: isBulkDeleteModalOpen,
		openModal: openBulkDeleteModal,
		closeModal: closeBulkDeleteModal
	} = useModal()

	const { mutate: couponDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.delete(`/coupons/${idToBeRemoves}/`)
		},
		{
			onSuccess: () => {
				successMessage()
				queryClient.invalidateQueries('fetchCoupons')
			},
			onError: () => {
				failMessage()
			},
			onSettled: () => {
				closeDeleteModal()
			}
		}
	)

	const { mutate: bulkDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.post('/coupons/bulk_delete/', { ids: checkedCoupons })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchCoupons')
				bulkDeleteSuccessMsg()
			},
			onError: () => {
				bulkDeleteFailMsg()
			},
			onSettled: () => {
				setCheckedCoupons([])
				closeBulkDeleteModal()
			}
		}
	)

	const handleDelete = (id: number) => {
		setIdToBeRemoved(id)
		openDeleteModal()
	}

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value
		switch (action) {
			case 'delete':
				openBulkDeleteModal()
				break
		}
	}

	const tableHeaders = [
		{
			id: 'coupon',
			label: t('coupons.tableHeaders.coupon')
		}
	]

	const actionFilters = [
		{
			id: 'actions',
			label: t('coupons.actions.label'),
			placeholder: t('coupons.actions.placeholder'),
			options: [
				{
					value: 'delete',
					label: t('coupons.actions.options.delete')
				}
			]
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedCoupons(state.ids)
	}

	const handleEdit = (id: number) => {
		navigate(`/coupons/${id}`)
	}

	const handleDeleteModalOpen = (id: number) => {
		handleDelete(id)
	}

	const contextMenuOptions = [
		{
			label: t('coupons.coupon.ellipsisMenu.edit'),
			action: handleEdit
		},
		{
			label: t('coupons.coupon.ellipsisMenu.delete'),
			action: handleDeleteModalOpen
		}
	]

	const columns: Column[] = [
		{
			label: t('coupons.tableHeaders.coupon'),
			renderCell: (item: any) => (
				<NavLink style={{ color: theme`colors.blueRibbon` }} to={`/coupons/${item.id}`}>
					{item.code}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('coupons.tableHeaders.created'),
			renderCell: (item: any) => (
				<>
					<div>{format(parseISO(item.created), 'yyyy-MM-dd')}</div>
					<div>{format(parseISO(item.created), 'HH:mm:ss')}</div>
				</>
			)
		},
		{
			label: t('coupons.tableHeaders.priceDiscount'),
			renderCell: (item: any) => (
				<>
					{item.price_discount.value}
					{item.price_discount.is_percentage ? '%' : ''}
				</>
			)
		},
		{
			label: t('coupons.tableHeaders.timesUsed'),
			renderCell: (item: any) => <>{item?.times_used}</>
		},
		{
			label: t('coupons.tableHeaders.isActive'),
			renderCell: (item: any) => <>{item?.validity?.is_active ? 'Yes' : 'No'}</>
		},
		{
			label: t('coupons.tableHeaders.expirationDate'),
			renderCell: (item: any) => (
				<>
					<div>{format(parseISO(item?.validity?.expiration_date), 'yyyy-MM-dd')}</div>
				</>
			)
		},
		// {
		// 	label: t('coupons.tableHeaders.commissionDiscount'),
		// 	renderCell: (item: any) => (
		// 		<>
		// 			{item.commission_discount.value}
		// 			{item.commission_discount.is_percentage ? '%' : ''}
		// 		</>
		// 	)
		// },
		{
			label: '',
			renderCell: (item: any) => <ContextMenu dataId={item.id} options={contextMenuOptions} />
		}
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns:  40px repeat(${columns.length - 1}, 1fr) 40px;
	`
	}

	return (
		<>
			<HeaderContainer>
				<StyledHeader
					title={t('coupons.title')}
					needMarginBottom={isUser}
					handleSearch={handleSearch}
				/>
				<DesktopCtaContainer>
					<CtaLink to="/coupons/add" text={t('coupons.cta')} />
				</DesktopCtaContainer>
			</HeaderContainer>
			{!isUser && (
				<StyledFiltersWrapper>
					<FiltersContainer>
						<StyledSearchInput
							label={t('coupons.filters.search.label')}
							placeholder={t('coupons.filters.search.placeholder')}
							onChange={handleSearch}
						/>
						<Filters
							filters={actionFilters}
							disableActions={!checkedCoupons.length}
							handleFilter={handleActionFilter}
						/>
					</FiltersContainer>
					<MobileCtaContainer>
						<CtaLink to="/coupons/add" text={t('coupons.cta')} />
					</MobileCtaContainer>
				</StyledFiltersWrapper>
			)}
			{couponsData.nodes != null && Array.isArray(couponsData.nodes) && couponsData.nodes.length ? (
				<TableWrapper>
					{isMobileOrTablet ? (
						<StyledTable
							isLoading={loadingCoupons}
							prev={coupons?.data?.previous}
							next={coupons?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{coupons?.data?.results.map((coupon: any) => (
								<Coupon key={coupon.id} {...coupon} openDeleteModal={handleDelete} />
							))}
						</StyledTable>
					) : (
						<StyledTable2
							columns={columns}
							data={couponsData}
							isLoading={loadingCoupons}
							select={select}
							prev={coupons?.data?.previous}
							next={coupons?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}
					{isDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('coupons.modal.delete.title')}
							isOpened={isDeleteModalOpen}
							closeModal={closeDeleteModal}
							confirm={couponDeleteMutation}>
							<p>{t('coupons.modal.delete.text')}</p>
						</DeleteConfirmModal>
					)}
					{isBulkDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('coupons.modal.bulkDelete.title')}
							isOpened={isBulkDeleteModalOpen}
							closeModal={closeBulkDeleteModal}
							confirm={bulkDeleteMutation}>
							<p>{t('coupons.modal.bulkDelete.text', { count: checkedCoupons.length })}</p>
						</DeleteConfirmModal>
					)}
				</TableWrapper>
			) : (
				<EmptyTable isLoading={loadingCoupons} />
			)}
		</>
	)
}

export default CouponsPage
