import styled from 'styled-components'
import tw, { theme } from 'twin.macro'

import Filters, { Selects } from 'components/Filters'
import Table from 'components/Ui/Table'
import SearchInput from 'components/Ui/SearchInput'
import DatePickerInput from 'components/Ui/Datepicker'
import SortSelect from 'components/Ui/Select/Sort'
import Table2 from 'components/Ui/Table2'
import Header from 'components/Header'
import Select from 'components/Ui/Select'
import { Header as TableHeader } from 'components/Ui/Table'

export const StyledTable = styled(Table)`
	${TableHeader} {
		grid-template-columns: 13px repeat(12, 1fr);

		& > *:nth-child(1) {
			text-align: left;
		}
	}
`

export const StyledSearchInput = styled(SearchInput)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`

export const FiltersModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`

export const ModalFilters = styled(Filters)`
	display: flex;

	${Selects} {
		width: 100%;

		& > div {
			width: 100%;
		}
	}
`

export const StyledDatePickerInput = styled(DatePickerInput)`
	display: none !important;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex !important;
	}
`

export const DesktopSortSelect = styled(SortSelect)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: block;
	}
`

export const StyledTable2 = styled(Table2)`
	height: 65vh;

	@media (min-width: ${theme`screens.md`}) {
		height: calc(100vh - 59px - 164px);
	}

	@media (min-width: ${theme`screens.lg`}) {
		height: calc(100vh - 2rem - 27px - 90px - 212px);
	}

	@media (min-width: 1667px) {
		height: calc(100vh - 2rem - 27px - 215px);
	}
`

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

export const DesktopCtaContainer = styled.div`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		width: auto;
		padding: 0;
		margin-left: auto;
		display: block;
	}
`

export const StyledHeader = styled(Header)`
	width: 100%;
`

export const CtaContainer = styled.div`
	width: 100%;
	padding: 0 20px;

	@media (min-width: ${theme`screens.md`}) {
		width: auto;
		padding: 0;
	}
`

export const MobileCtaContainer = styled(CtaContainer)`
	@media (min-width: ${theme`screens.lg`}) {
		display: none;
	}
`

export const UserNameCell = tw.div`flex flex-col`

export const UserEmail = tw.span`text-xs`

export const DesktopSelect = styled(Select)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`
