import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'libs'
import axios from 'axios'
import * as yup from 'yup'
import useFetchStoreData from 'hooks/useFetchStoreData'
import Input from 'components/Ui/Input'
import { Button } from 'components/Ui'
import { AuthContext } from 'store/AuthContext'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import LanguageSwitcher from 'components/LanguageSwitcher'
import { theme } from 'twin.macro'
// import { loginValidationSchema } from 'schemas/validation'

const LoginPage = () => {
	const { t } = useTranslation()
	const { setAuth } = useContext(AuthContext)
	const storeCtx = useContext(StoreSettingsContext)
	const navigate = useNavigate()
	const loginValidationSchema = yup.object({
		email: yup
			.string()
			.email(t('validation.login.email.email'))
			.required(t('validation.login.email.required')),
		password: yup
			.string()
			.required(t('validation.login.password.required'))
			.min(7, t('validation.login.password.min'))
		// .matches(
		// 	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/,
		// 	'Password must contain at least 1 small letter, 1 big letter, 1 special character, 1 number'
		// )
	})
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(loginValidationSchema),
		mode: 'onChange'
	})

	useFetchStoreData({
		name: 'fetchStoreData',
		endpoint: '/store/',
		options: {
			onSuccess: async (res: any) => {
				await storeCtx?.setStoreSettings(res.data)
			},
			enabled: true
		}
	})

	const { mutate: loginMutation, isLoading: loadingLogin } = useMutation(
		(data) => {
			return axios.post('/login/', data)
		},
		{
			onSuccess: (res) => {
				localStorage.setItem('refresh', res.data.refresh)

				setAuth((prev: any) => ({ ...prev, accessToken: res.data.access }))
				navigate('/')
			},
			onError: (data: any) => {
				const errorMessage = data.response.data.detail

				setError('password', { type: 'custom', message: errorMessage })
			}
		}
	)

	const onSubmit = (data: any) => {
		loginMutation(data)
	}

	return (
		<StyledSection>
			<StyledForm onSubmit={handleSubmit(onSubmit)}>
				<LanguageSwitcherContainer>
					<LanguageSwitcher />
				</LanguageSwitcherContainer>
				{storeCtx?.storeSettings?.logo && (
					<StyledImage src={storeCtx?.storeSettings?.logo} alt="Logo" />
				)}
				<Header>
					<Title>{t('login.title')}</Title>
				</Header>
				<Inputs>
					<Input
						id="email"
						label={t('login.email')}
						{...register('email')}
						error={(errors as any).email}
					/>
					<Input
						id="password"
						label={t('login.password')}
						type="password"
						{...register('password')}
						error={(errors as any).password}
					/>
					<StyledLink to="/password-reset">Reset password</StyledLink>
				</Inputs>
				<StyledButton
					type="submit"
					text={t('login.submit')}
					icon={faArrowRight}
					isLoading={loadingLogin}
				/>
			</StyledForm>
		</StyledSection>
	)
}

const StyledSection = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 1rem;
`

const StyledForm = styled.form`
	max-width: 362px;
	box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.12);
	border-radius: 10px;
	padding: 30px 24px;
	width: 100%;
`

const StyledButton = styled(Button)`
	background-color: #525252;
	display: block;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 10px;
	color: #fff;
	font-weight: 900;
	margin-top: 30px;
`

const Inputs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const StyledImage = styled.img`
	display: block;
	margin: 0 auto 20px;
	max-height: 100px;
`

const Title = styled.h1`
	font-size: 1rem;
	color: #5e5e5e;
	font-weight: 600;
`

const Header = styled.div`
	margin-bottom: 25px;
`

const LanguageSwitcherContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	font-weight: bold;
	font-size: 0.875rem;
	color: #676767;
`

const StyledLink = styled(Link)`
	align-self: flex-end;
	color: ${theme`colors.accent`};
	font-weight: 700;
	font-size: 0.75rem;
`

export default LoginPage
