import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'libs'
import { Column } from '@table-library/react-table-library/compact'
import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import { InfoHeader, SalesTableWrapper } from './styles'
import { customTableStyles } from 'styles/general'
import { format } from 'date-fns'
import { useFetchData } from 'hooks'
import { Spinner } from 'components/Ui/Spinner'
import { InfoHeaderContainer, InfoHeaderLink } from 'pages/booking'

export interface UserBookingsTableProps {
	user_id?: string
}

export const UserBookingsTable = ({ user_id }: UserBookingsTableProps) => {
	const { t } = useTranslation()
	const [bookingsData, setBookingsData] = useState({ nodes: [] })

	const { isFetching: bookingsLoading } = useFetchData({
		name: 'fetchBookings',
		query: `user_id=${user_id}`,
		endpoint: '/bookings/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setBookingsData({ nodes: results })
			}
		}
	})

	if (bookingsData?.nodes?.length == 0) {
		// TODO: empty sales content
		return (
			<>
				<InfoHeader>Bookings</InfoHeader>
				<div>No bookings yet</div>
			</>
		)
	}

	const columns: Column[] = [
		{
			label: t('sales.tableHeaders.booking'),
			renderCell: (item: any) => <NavLink to={`/bookings/${item.id}`}>{item.ref_nr}</NavLink>,
			select: true,
			pinLeft: true
		},
		{
			renderCell: (item: any) => format(new Date(item.start_date), 'dd.MM.yyyy'),
			label: t('bookings.tableHeaders.from')
		},
		{
			renderCell: (item: any) => format(new Date(item.end_date), 'dd.MM.yyyy'),
			label: t('bookings.tableHeaders.to')
		},
		// customers only see active bookings.
		// ...(userRoles.isStaff
		// 	? [
		// 			{
		// 				label: t('bookings.tableHeaders.status'),
		// 				renderCell: (item: any) => <Status status={item.is_active} />
		// 			}
		// 	  ]
		// 	: []),
		{
			renderCell: (item: any) => item.booth?.booth_type.name,
			label: t('bookings.tableHeaders.type')
		},
		{
			renderCell: (item: any) => item.booth?.number && `#${item.booth?.number}`,
			label: t('bookings.tableHeaders.booth')
		},
		{
			renderCell: (item: any) => item.sold_quantity,
			label: t('bookings.tableHeaders.productsSold')
		},
		{
			renderCell: (item: any) => item.remaining_products,
			label: t('bookings.tableHeaders.productsRemaining')
		}
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
			${customTableStyles.Table};
			--data-table-library_grid-template-columns: 165px repeat(${columns.length - 1}, 1fr);
		`
	}

	const theme = useTheme([getTheme(), desktopTableStyles])

	return (
		<>
			<InfoHeaderContainer>
				<InfoHeader>Bookings</InfoHeader>
				{!!bookingsData?.nodes?.length && (
					<InfoHeaderLink to={'/bookings?user_id=' + user_id}>View all</InfoHeaderLink>
				)}
			</InfoHeaderContainer>
			<SalesTableWrapper>
				{bookingsLoading && <Spinner></Spinner>}
				{bookingsData.nodes != null &&
				Array.isArray(bookingsData?.nodes) &&
				bookingsData?.nodes.length ? (
					<CompactTable
						columns={columns}
						data={bookingsData}
						theme={theme}
						isLoading={bookingsLoading}
						layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}></CompactTable>
				) : (
					<></>
				)}
			</SalesTableWrapper>
		</>
	)
}
