import { useContext, useRef, useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Column } from '@table-library/react-table-library/compact'
import { useTranslation } from 'libs'
import { useMutation, useQueryClient } from 'react-query'
import { theme } from 'twin.macro'

import { Product } from 'components/Product'
import FiltersWrapper from 'components/FiltersWrapper'
import MobileFilters from 'components/Filters/mobile'
import Filters from 'components/Filters'
import Select from 'components/Ui/Select'
import { useFetchData } from 'hooks'
import { usePagination } from 'hooks'
import { useSearch } from 'hooks'
import { useFilter } from 'hooks'
import { useFetchInfinite } from 'hooks'
import CtaLink from 'components/Ui/CtaLink'
import { useModal } from 'hooks'
import FiltersModal from 'components/Modals/filters'
import SortModal from 'components/Modals/sort'
import EmptyTable from 'components/Ui/Table/Empty'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import ConfirmModal from 'components/Modals/confirm'
import { IActionFilter } from 'interfaces/filters.interface'
import {
	StyledSearchInput,
	DesktopSortSelect,
	DesktopCtaContainer,
	MobileCtaContainer,
	StyledTable,
	FiltersModalContent,
	ModalFilters,
	DesktopSelect,
	HeaderContainer,
	StyledHeader,
	StyledTable2,
	ImageElement,
	InfoDetails,
	Sku,
	StyledTableImage,
	Name,
	PrintContainer,
	UserNameCell,
	UserEmail,
	BookingCell,
	BookingReference,
	PrintQuantityInput,
	BulkPrintItemRow,
	BulkPrintItems,
	BulkPrintHeader,
	BulkPrintHeaderPrint
} from 'pages/products/styles'
import FormatModal from 'components/Modals/format'
import { IOption } from 'interfaces/select.interface'
import { defaultConfig } from 'config/default'
import { downloadAs } from 'utils/downloadFile'
import SortSelect from 'components/Ui/Select/Sort'
import { customTableStyles, TableWrapper, FiltersContainer } from 'styles/general'
import { useDevice } from 'hooks'
import ContextMenu from 'components/Ui/ContextMenu'
import { Currency } from 'components/Ui'
import Status from 'components/Status'
import { useReactToPrint } from 'react-to-print'
import QRCode from 'react-qr-code'
import { EllipsisText } from 'components/Ui'
import { useUserRoles } from 'hooks'
import { useTableSelect } from 'hooks'
import { AuthContext } from 'store/AuthContext'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import UserSelect from 'components/UserSelect'

import type { IProduct } from 'components/Product/types'

type CheckedProduct = IProduct & { __print_quantity?: number }

const ProductsPage = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { auth } = useContext(AuthContext)

	const { handlePagination } = usePagination(setQuery)
	const { handleSearch } = useSearch({ name: 'search', cb: setQuery })
	const { handleFilter } = useFilter(setQuery)
	const { isStaff, isUser } = useUserRoles()
	const axiosPrivate = useAxiosPrivate()
	const [chosenProductId, setChosenProductId] = useState<number | null>(null)
	const [checkedProducts, setCheckedProducts] = useState<number[]>([])
	const [chosenExportFormat, setChosenExportFormat] = useState<number | string | null>(null)
	const queryClient = useQueryClient()
	const { isMobileOrTablet } = useDevice()
	const [productsData, setProductsData] = useState<{ nodes: IProduct[] }>({ nodes: [] })
	const [checkedProductsData, setCheckedProductsData] = useState<{
		nodes: CheckedProduct[]
	}>({
		nodes: []
	})
	const { select } = useTableSelect(productsData, onSelectChange)
	const navigate = useNavigate()
	const storeCtx = useContext(StoreSettingsContext)
	const locale = storeCtx?.storeSettings?.country

	const [isPrinting, setIsPrinting] = useState<boolean>(false)
	const printRef = useRef<any>()

	// We store the resolve Promise being used in `onBeforeGetContent` here
	const promiseResolveRef = useRef<any>(null)

	// We watch for the state to change here, and for the Promise resolve to be available
	useEffect(() => {
		if (isPrinting && promiseResolveRef.current) {
			// Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
			promiseResolveRef.current()
		}
	}, [isPrinting])

	const handlePrintComponent = useReactToPrint({
		content: () => printRef.current,
		onBeforeGetContent: () => {
			return new Promise((resolve) => {
				promiseResolveRef.current = resolve
				setIsPrinting(true)
			})
		},
		onAfterPrint: () => {
			// Reset the Promise resolve so we can print again
			promiseResolveRef.current = null
			setIsPrinting(false)
		}
	})

	const {
		isOpen: isFiltersOpen,
		openModal: openFiltersModal,
		closeModal: closeFiltersModal
	} = useModal()
	const { isOpen: isSortOpen, openModal: openSortModal, closeModal: closeSortModal } = useModal()

	const { data: products, isFetching: productsLoading } = useFetchData({
		name: 'fetchProducts',
		query,
		endpoint: '/products/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setProductsData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const {
		data: categories,
		isLoading: loadingCategories,
		fetchNextPage: fetchNextCategories
	} = useFetchInfinite({
		name: 'fetchCategories',
		endpoint: '/categories/',
		options: {
			enabled: !!auth.accessToken
		}
	})

	const {
		data: bookings,
		isLoading: loadingBookings,
		fetchNextPage
	} = useFetchInfinite({
		name: 'fetchBookings',
		endpoint: '/bookings/',
		query: 'is_active=true',
		options: {
			enabled: !!auth.accessToken
		}
	})

	const handleFormatChange = (data: IOption) => {
		data?.value?.toString() ? setChosenExportFormat(data.value) : setChosenExportFormat(null)
	}

	const {
		isOpen: isSingleDeleteModalOpen,
		openModal: openSingleDeleteModal,
		closeModal: closeSingleDeleteModal
	} = useModal()
	const {
		isOpen: isBulkDeleteModalOpen,
		openModal: openBulkDeleteModal,
		closeModal: closeBulkDeleteModal
	} = useModal()
	const {
		isOpen: isPublishModalOpen,
		openModal: openPublishModal,
		closeModal: closePublishModal
	} = useModal()
	const {
		isOpen: isPrintModalOpen,
		openModal: openPrintModal,
		closeModal: closePrintModal
	} = useModal()
	const {
		isOpen: isBulkPrintModalOpen,
		openModal: openBulkPrintModal,
		closeModal: closeBulkPrintModal
	} = useModal()
	const {
		isOpen: isUnpublishModalOpen,
		openModal: openUnpublishModal,
		closeModal: closeUnpublishModal
	} = useModal()
	const {
		isOpen: isShopifyModalOpen,
		openModal: openShopifyModal,
		closeModal: closeShopifyModal
	} = useModal()
	const {
		isOpen: isExportModalOpen,
		openModal: openExportModal,
		closeModal: closeExportModal
	} = useModal()
	const {
		isOpen: isFormatModalOpen,
		openModal: openFormatModal,
		closeModal: closeFormatModal
	} = useModal()

	const shopifySuccess = useToast({
		type: 'success',
		message: t('products.modal.shopify.success')
	})
	const shopifyFail = useToast({
		type: 'error',
		message: t('products.modal.shopify.error')
	})

	const publishBulkSuccess = useToast({
		type: 'success',
		message: t('products.modal.publish.success')
	})
	const publishBulkFail = useToast({
		type: 'error',
		message: t('products.modal.publish.error')
	})

	const unpublishBulkSuccess = useToast({
		type: 'success',
		message: t('products.modal.unpublish.success')
	})
	const unpublishBulkFail = useToast({
		type: 'error',
		message: t('products.modal.unpublish.error')
	})

	const printBulkSuccess = useToast({
		type: 'success',
		message: t('products.modal.bulkPrint.success')
	})
	const printBulkFail = useToast({
		type: 'error',
		message: t('products.modal.bulkPrint.error')
	})

	const printSuccess = useToast({
		type: 'success',
		message: t('products.modal.print.success')
	})
	const printFail = useToast({
		type: 'error',
		message: t('products.modal.print.error')
	})

	const exportBulkSuccess = useToast({
		type: 'success',
		message: t('products.modal.export.success')
	})
	const exportBulkFail = useToast({
		type: 'error',
		message: t('products.modal.export.error')
	})

	const deleteSuccess = useToast({
		type: 'success',
		message: t('products.modal.delete.success')
	})
	const deleteFail = useToast({
		type: 'error',
		message: t('products.modal.delete.error')
	})

	const deleteBulkSuccess = useToast({
		type: 'success',
		message: t('products.modal.bulkDelete.success')
	})
	const deleteBulkFail = useToast({
		type: 'error',
		message: t('products.modal.bulkDelete.error')
	})

	const productStatusUpdateSuccessMsg = useToast({
		type: 'success',
		message: t('product.status.success')
	})
	const productStatusUpdateFailMsg = useToast({
		type: 'error',
		message: t('product.status.error')
	})

	const { mutate: productDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.delete(`/products/${chosenProductId}/`)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				deleteSuccess()
			},
			onError: () => {
				deleteFail()
			},
			onSettled: () => {
				closeSingleDeleteModal()
			}
		}
	)

	const { mutate: productsDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.post('/products/bulk_delete/', { ids: checkedProducts })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				deleteBulkSuccess()
			},
			onError: () => {
				deleteBulkFail()
			},
			onSettled: () => {
				setCheckedProducts([])
				closeBulkDeleteModal()
			}
		}
	)

	const handleDelete = (id: number) => {
		setChosenProductId(id)
		openSingleDeleteModal()
	}

	const handlePrint = (id: number) => {
		setCheckedProducts([id])

		const checkedProductsFullData: CheckedProduct[] = productsData.nodes.filter(
			(productData: any) => productData.id === +id
		)
		const prod = checkedProductsFullData
		let print_quantity = prod[0].quantity - prod[0].print_count
		if (print_quantity <= 0) {
			print_quantity = 1
		}
		prod[0].__print_quantity = print_quantity

		setCheckedProductsData({ nodes: checkedProductsFullData })
		openPrintModal()
	}

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value

		switch (action) {
			case 'delete':
				openBulkDeleteModal()
				break
			case 'publish':
				openPublishModal()
				break
			case 'print':
				{
					const _products = checkedProductsData.nodes
					for (let i = 0; i < _products.length; i++) {
						let print_quantity = _products[i].quantity - _products[i].print_count
						if (print_quantity <= 0) {
							print_quantity = 1
						}
						_products[i].__print_quantity = print_quantity
					}
					setCheckedProductsData({ nodes: _products })
					openBulkPrintModal()
				}
				break
			case 'unpublish':
				openUnpublishModal()
				break
			case 'shopify':
				openShopifyModal()
				break
			case 'export':
				openExportModal()
				break
		}
	}

	const { mutate: shopifyMutation } = useMutation(
		() => {
			return axiosPrivate.post('/products/sync_shopify/', { ids: checkedProducts })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				shopifySuccess()
			},
			onError: () => {
				shopifyFail()
			},
			onSettled: () => {
				setCheckedProducts([])
				closeShopifyModal()
			}
		}
	)

	const { mutate: publishBulkMutation } = useMutation(
		() => {
			return axiosPrivate.post('/products/make_published/', { ids: checkedProducts })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				publishBulkSuccess()
			},
			onError: () => {
				publishBulkFail()
			},
			onSettled: () => {
				setCheckedProducts([])
				closePublishModal()
			}
		}
	)

	const { mutate: unpublishBulkMutation } = useMutation(
		() => {
			return axiosPrivate.post('/products/make_unpublished/', { ids: checkedProducts })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				unpublishBulkSuccess()
			},
			onError: () => {
				unpublishBulkFail()
			},
			onSettled: () => {
				setCheckedProducts([])
				closeUnpublishModal()
			}
		}
	)

	const { mutate: exportBulkMutation, isLoading: isExportLoading } = useMutation(
		() => {
			return axiosPrivate.post('/products/export/', {
				format: chosenExportFormat,
				ids: checkedProducts
			})
		},
		{
			onSuccess: (res) => {
				const format = defaultConfig.exportFormatOptions
					.find((format) => format.value === chosenExportFormat)
					?.label.toLowerCase()

				if (format) {
					downloadAs({ file: res.data, name: 'products-export', format })
				}

				setCheckedProducts([])
				exportBulkSuccess()
			},
			onError: () => {
				exportBulkFail()
			},
			onSettled: () => {
				closeFormatModal()
			}
		}
	)

	const { mutate: printProductMutation } = useMutation(
		() => {
			handlePrintComponent()
			const printed = {
				id: checkedProductsData.nodes[0].id,
				quantity: checkedProductsData.nodes[0].__print_quantity
			}
			return axiosPrivate.post('/products/print_labels/', { products: [printed] })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				printSuccess()
			},
			onError: () => {
				printFail()
			},
			onSettled: () => {
				setCheckedProducts([])
				closePrintModal()
			}
		}
	)

	const { mutate: printBulkProductMutation } = useMutation(
		() => {
			handlePrintComponent()
			const printed = checkedProductsData.nodes.map((item) => {
				return { id: item.id, quantity: item.__print_quantity }
			})

			return axiosPrivate.post('/products/print_labels/', { products: printed })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				printBulkSuccess()
			},
			onError: () => {
				printBulkFail()
			},
			onSettled: () => {
				setCheckedProducts([])
				closeBulkPrintModal()
			}
		}
	)

	const handleExportModal = () => {
		closeExportModal()
		openFormatModal()
	}

	const filters = [
		{
			id: 'published',
			label: t('products.filters.status.label'),
			placeholder: t('products.filters.status.placeholder'),
			options: [
				{
					value: 'true',
					label: t('products.filters.status.options.published')
				},
				{
					value: 'false',
					label: t('products.filters.status.options.unpublished')
				}
			]
		},
		{
			id: 'printed',
			label: t('products.filters.printed.label'),
			placeholder: t('products.filters.printed.placeholder'),
			options: [
				{
					value: 'true',
					label: t('products.filters.printed.options.printed')
				},
				{
					value: 'false',
					label: t('products.filters.printed.options.unprinted')
				}
			]
		}
	]

	const sortingFilters = {
		id: 'ordering',
		label: t('products.filters.sort.label'),
		placeholder: t('products.filters.sort.placeholder'),
		options: [
			{
				value: 'name',
				label: `${t('products.filters.sort.options.name')} asc`
			},
			{
				value: '-name',
				label: `${t('products.filters.sort.options.name')} desc`
			},
			...(storeCtx?.storeSettings?.categories_enabled
				? [
						{
							value: 'category',
							label: `${t('products.filters.sort.options.category')} asc`
						},
						{
							value: '-category',
							label: `${t('products.filters.sort.options.category')} desc`
						}
				  ]
				: []),
			{
				value: 'published',
				label: `${t('products.filters.sort.options.published')} asc`
			},
			{
				value: '-published',
				label: `${t('products.filters.sort.options.published')} desc`
			},
			{
				value: 'booking__booth',
				label: `${t('products.filters.sort.options.booking')} asc`
			},
			{
				value: '-booking__booth',
				label: `${t('products.filters.sort.options.booking')} desc`
			},
			{
				value: 'price',
				label: `${t('products.filters.sort.options.price')} asc`
			},
			{
				value: '-price',
				label: `${t('products.filters.sort.options.price')} desc`
			},
			{
				value: 'updated',
				label: `${t('products.filters.sort.options.updated')} asc`
			},
			{
				value: '-updated',
				label: `${t('products.filters.sort.options.updated')} desc`
			}
		]
	}

	const actionFilters = [
		{
			id: 'actions',
			label: t('products.filters.actions.label'),
			placeholder: t('products.filters.actions.placeholder'),
			options: [
				...(isStaff
					? [
							{
								value: 'print',
								label: t('products.filters.actions.options.print'),
								isDisabled: checkedProducts.length == 0
							}
					  ]
					: []),

				{
					value: 'publish',
					label: t('products.filters.actions.options.publish'),
					isDisabled: checkedProducts.length == 0
				},
				{
					value: 'unpublish',
					label: t('products.filters.actions.options.unpublish'),
					isDisabled: checkedProducts.length == 0
				},
				...(isStaff
					? [
							{
								value: 'shopify',
								label: t('products.filters.actions.options.shopify'),
								isDisabled: checkedProducts.length == 0
							}
					  ]
					: []),

				{
					value: 'export',
					label: t('products.filters.actions.options.export')
				},
				{
					value: 'delete',
					label: t('products.filters.actions.options.delete'),
					isDisabled: checkedProducts.length == 0
				}
			]
		}
	]

	const tableHeaders = [
		{
			id: 'product',
			label: t('products.tableHeaders.product')
		},
		...(isStaff
			? [
					{
						id: 'owner',
						label: t('products.tableHeaders.product')
					}
			  ]
			: []),
		{
			id: 'category',
			label: t('products.tableHeaders.category')
		},
		{
			id: 'price',
			label: t('products.tableHeaders.price')
		},
		{
			id: 'quantity',
			label: t('products.tableHeaders.quantity')
		},
		{
			id: 'booth',
			label: t('products.tableHeaders.booth')
		},
		{
			id: 'sold',
			label: t('products.tableHeaders.sold')
		},
		{
			id: 'discount',
			label: t('products.tableHeaders.discount')
		},
		{
			id: 'totalSales',
			label: t('products.tableHeaders.totalSales')
		},
		{
			id: 'status',
			label: t('products.tableHeaders.status')
		},
		{
			id: 'printed',
			label: t('products.tableHeaders.printed')
		}
	]

	const handleEdit = (id: number) => {
		navigate(`/products/${id}`)
	}

	const handleEllipsisPrint = (id: number) => {
		handlePrint(id)
	}

	const handleEllipsisDelete = (id: number) => {
		handleDelete(id)
	}

	const { mutate: updateProductStatusMutation } = useMutation(
		(id: number) => {
			return axiosPrivate.patch(`/products/${id}/`, {
				published: !products?.data?.results?.find((item: any) => item.id === +id).published
			})
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				productStatusUpdateSuccessMsg()
			},
			onError: () => {
				productStatusUpdateFailMsg()
			}
		}
	)

	const handleStatusChange = (id: number) => {
		updateProductStatusMutation(id)
	}

	const contextMenuOptions = [
		{
			label: t('products.product.edit'),
			action: handleEdit
		},
		...(isStaff
			? [
					{
						label: t('products.product.print'),
						action: handleEllipsisPrint
					}
			  ]
			: []),

		{
			label: `${t('products.product.publish')}/${t('products.product.archive')}`,
			action: handleStatusChange,
			borderTop: !isMobileOrTablet,
			borderBottom: !isMobileOrTablet
		},
		{
			label: t('products.product.delete'),
			action: handleEllipsisDelete
		}
	]

	const columns: Column[] = [
		{
			label: t('products.tableHeaders.product'),
			renderCell: (item) => (
				<ImageElement>
					<StyledTableImage src={item.image_thumbnail} />
					<InfoDetails>
						<Name
							style={{ color: theme`colors.blueRibbon` }}
							to={
								searchParams.toString()
									? `/products/${item.id}?${searchParams.toString()}`
									: `/products/${item.id}`
							}>
							{item.name.length > 20 ? `${item.name.slice(0, 20)}...` : item.name}
						</Name>
						<Sku>{item.sku}</Sku>
					</InfoDetails>
				</ImageElement>
			),

			select: true,
			pinLeft: true
		},
		...(isStaff
			? [
					{
						label: t('products.tableHeaders.owner'),
						renderCell: (item: IProduct) => (
							<UserNameCell>
								<div>{item.owner?.name}</div>
								<UserEmail>{item.owner?.email}</UserEmail>
							</UserNameCell>
						)
					}
			  ]
			: [
					{
						label: '',
						renderCell: () => ''
					}
			  ]),
		...(storeCtx?.storeSettings?.categories_enabled
			? [
					{
						label: t('products.tableHeaders.category'),
						renderCell: (item: any) => (item.category?.id ? item.category.name : '')
					}
			  ]
			: [
					{
						label: '',
						renderCell: () => ''
					}
			  ]),
		{
			label: t('products.tableHeaders.size'),
			renderCell: (item) => item.size
		},
		{
			label: t('products.tableHeaders.price'),
			renderCell: (item) => <Currency price={item.price} />
		},
		{
			label: t('products.tableHeaders.quantity'),
			renderCell: (item) => item.quantity
		},
		{
			label: t('products.tableHeaders.booth'),
			renderCell: (item) => {
				if (item.booking?.id) {
					return (
						<BookingCell>
							<div>
								<span>{item.booking?.booth?.booth_type?.name}</span>
								{item.booking?.booth?.number && <span> #{item.booking.booth.number}</span>}
							</div>
							<BookingReference>
								<span>#{item.booking?.reference}</span> <span>{item.booking.start_date}</span>
							</BookingReference>
						</BookingCell>
					)
				} else {
					return <div></div>
				}
			}
		},
		{
			label: t('products.tableHeaders.sold'),
			renderCell: (item) => item.sum_sold_quantity
		},
		{
			label: t('products.tableHeaders.discount'),
			renderCell: (item) => <Currency price={item.sum_sold_discount} />
		},
		{
			label: t('products.tableHeaders.totalSales'),
			renderCell: (item) => <Currency price={item.sum_sold_total} />
		},
		{
			label: t('products.tableHeaders.status'),
			renderCell: (item) => <Status status={item.published} />
		},
		{
			label: t('products.tableHeaders.printed'),
			renderCell: (item) => (
				<Status status={!!item.print_count} showText={false} deactivatedColor={theme`colors.red`} />
			)
		},
		{
			label: '',
			renderCell: (item: any) => <ContextMenu dataId={item.id} options={contextMenuOptions} />
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedProducts(state.ids)
		const checkedProductsFullData = productsData.nodes.filter((productData: any) => {
			return state.ids.includes(productData.id)
		})
		setCheckedProductsData({ nodes: checkedProductsFullData })
	}

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
			${customTableStyles.Table};
			--data-table-library_grid-template-columns:  40px 3fr 2fr repeat(${
				columns.length - 5
			}, 1fr) 1.5fr 1fr 40px;
	`
	}

	return (
		<>
			<HeaderContainer>
				<StyledHeader title={t('products.title')} handleSearch={handleSearch} />
				<DesktopCtaContainer>
					<CtaLink to="/products/add" text={t('products.cta')} />
				</DesktopCtaContainer>
			</HeaderContainer>
			<FiltersWrapper>
				<FiltersContainer>
					<StyledSearchInput
						label={t('products.filters.search.label')}
						placeholder={t('products.filters.search.placeholder')}
						onChange={handleSearch}
					/>
					{storeCtx?.storeSettings?.categories_enabled && (
						<DesktopSelect
							isLoading={loadingCategories}
							key="category"
							id="category"
							placeholder={t('filters.placeholder')}
							label={t('filters.category')}
							options={
								!loadingCategories && categories != null && Array.isArray(categories?.pages)
									? categories?.pages
											.reduce((total: any, page: any) => {
												return [...total, ...page.results]
											}, [])
											.map((option: any) => ({
												label: option.name,
												value: option.id
											}))
									: []
							}
							handleInfiniteScroll={fetchNextCategories}
							onChange={(data) => handleFilter({ id: 'category', value: data?.value || null })}
						/>
					)}
					<DesktopSelect
						isLoading={loadingBookings}
						key="booking"
						id="booking"
						placeholder={t('filters.placeholder')}
						label={t('filters.booking')}
						options={
							!loadingBookings && bookings != null && Array.isArray(bookings?.pages)
								? bookings?.pages
										.reduce((total: any, page: any) => {
											return Array.isArray(page.results) && [...total, ...page.results]
										}, [])
										.map((booking: any) => {
											return {
												label: booking?.booth?.number
													? `#${booking.booth.number} ${booking.booth.booth_type.name} - (#${booking.ref_nr} ${booking.start_date})`
													: `#${booking.ref_nr} ${booking.start_date}`,
												value: booking.id
											}
										})
								: []
						}
						handleInfiniteScroll={fetchNextPage}
						onChange={(data: any) => handleFilter({ id: 'booking_id', value: data?.value || null })}
					/>
					<Filters filters={filters} handleFilter={handleFilter} />

					{!isUser && (
						<UserSelect
							id="owner"
							key="owner"
							label={t('filters.owner')}
							placeholder={t('filters.placeholder')}
							onChange={(data: any) => handleFilter({ id: 'owner', value: data?.value || null })}
						/>
					)}

					<Filters
						filters={actionFilters}
						disableActions={false}
						handleFilter={handleActionFilter}
					/>
				</FiltersContainer>
				<DesktopSortSelect {...sortingFilters} onChange={handleFilter} />
				<MobileCtaContainer>
					<CtaLink to="/products/add" text={t('products.cta')} />
				</MobileCtaContainer>
				<MobileFilters openFilters={openFiltersModal} openSort={openSortModal} />
			</FiltersWrapper>

			{productsData.nodes != null &&
			Array.isArray(productsData.nodes) &&
			productsData?.nodes.length ? (
				<TableWrapper>
					{isMobileOrTablet ? (
						<StyledTable
							isLoading={productsLoading}
							prev={products?.data?.previous}
							next={products?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{products?.data?.results?.map((product: any) => (
								<Product
									key={product.id}
									{...product}
									openDeleteModal={handleDelete}
									openPrintModal={handlePrint}
								/>
							))}
						</StyledTable>
					) : (
						<StyledTable2
							columns={columns}
							data={productsData}
							isLoading={productsLoading}
							select={select}
							prev={products?.data?.previous}
							next={products?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}
				</TableWrapper>
			) : (
				<EmptyTable isLoading={productsLoading} />
			)}
			{isFiltersOpen && (
				<FiltersModal isOpened={isFiltersOpen} closeModal={closeFiltersModal}>
					<FiltersModalContent>
						{storeCtx?.storeSettings?.categories_enabled && (
							<Select
								isLoading={loadingCategories}
								key="category"
								id="category"
								placeholder={t('filters.placeholder')}
								label={t('filters.category')}
								options={
									!loadingCategories && categories != null && Array.isArray(categories?.pages)
										? categories?.pages
												.reduce((total: any, page: any) => {
													return [...total, ...page.results]
												}, [])
												.map((option: any) => ({
													label: option.name,
													value: option.id
												}))
										: []
								}
								handleInfiniteScroll={fetchNextCategories}
								onChange={(data) => handleFilter({ id: 'category', value: data?.value || null })}
							/>
						)}
						<Select
							isLoading={loadingBookings}
							key="booking"
							id="booking"
							placeholder={t('filters.placeholder')}
							label={t('filters.booking')}
							options={
								!loadingBookings && bookings != null && Array.isArray(bookings?.pages)
									? bookings?.pages
											.reduce((total: any, page: any) => {
												return Array.isArray(page.results) && [...total, ...page.results]
											}, [])
											.map((booking: any) => {
												return {
													label: booking?.booth?.number
														? `#${booking.booth.number} ${booking.booth.booth_type.name} - (#${booking.ref_nr} ${booking.start_date})`
														: `#${booking.ref_nr} ${booking.start_date}`,
													value: booking.id
												}
											})
									: []
							}
							handleInfiniteScroll={fetchNextPage}
							onChange={(data: any) =>
								handleFilter({ id: 'booking_id', value: data?.value || null })
							}
						/>
						<ModalFilters filters={filters} handleFilter={handleFilter} />
						{isStaff && (
							<UserSelect
								id="owner"
								key="owner"
								label={t('filters.owner')}
								placeholder={t('filters.placeholder')}
								onChange={(data: any) => handleFilter({ id: 'owner', value: data?.value || null })}
							/>
						)}
					</FiltersModalContent>
				</FiltersModal>
			)}
			{isSortOpen && (
				<SortModal isOpened={isSortOpen} closeModal={closeSortModal}>
					<SortSelect {...sortingFilters} onChange={handleFilter} />
				</SortModal>
			)}
			{isSingleDeleteModalOpen && (
				<DeleteConfirmModal
					title={t('products.modal.delete.title')}
					isOpened={isSingleDeleteModalOpen}
					closeModal={closeSingleDeleteModal}
					confirm={productDeleteMutation}>
					<p>{t('products.modal.delete.text')}</p>
				</DeleteConfirmModal>
			)}
			{isBulkDeleteModalOpen && (
				<DeleteConfirmModal
					title={t('products.modal.bulkDelete.title')}
					isOpened={isBulkDeleteModalOpen}
					closeModal={closeBulkDeleteModal}
					confirm={productsDeleteMutation}>
					<p>{t('products.modal.bulkDelete.text', { count: checkedProducts.length })}</p>
				</DeleteConfirmModal>
			)}
			{isPublishModalOpen && (
				<ConfirmModal
					title={t('products.modal.publish.title')}
					isOpened={isPublishModalOpen}
					closeModal={closePublishModal}
					confirm={publishBulkMutation}>
					<p>{t('products.modal.publish.text', { count: checkedProducts.length })}</p>
				</ConfirmModal>
			)}
			{isPrintModalOpen && (
				<ConfirmModal
					title={t('products.modal.print.title')}
					isOpened={isPrintModalOpen}
					closeModal={closePrintModal}
					confirm={printProductMutation}>
					{/* confirm={handlePrintComponent}> */}
					{/* <p>{t('products.modal.print.text')}</p> */}
					<span>
						{t('products.modal.print.about_to_print')}{' '}
						<b>
							<PrintQuantityInput
								type="number"
								value={checkedProductsData.nodes[0].__print_quantity}
								onChange={(e) => {
									const prod = checkedProductsData.nodes[0]
									prod.__print_quantity = parseInt(e.target.value)
									setCheckedProductsData({ nodes: [prod] })
								}}
							/>
						</b>
						{t('products.modal.print.labels_for')}
						<b>{checkedProductsData.nodes[0].name}</b>.
					</span>
				</ConfirmModal>
			)}
			{isBulkPrintModalOpen && (
				<ConfirmModal
					title={t('products.modal.bulkPrint.title')}
					isOpened={isBulkPrintModalOpen}
					closeModal={closeBulkPrintModal}
					confirm={printBulkProductMutation}>
					{/* confirm={handlePrintComponent}> */}
					<p>{t('products.modal.bulkPrint.text', { count: checkedProducts.length })}</p>
					<BulkPrintItems>
						<BulkPrintHeader>
							<span>{t('products.modal.bulkPrint.headers.stock')}</span>
							<span>{t('products.modal.bulkPrint.headers.product')}</span>
							<BulkPrintHeaderPrint>
								{t('products.modal.bulkPrint.headers.print')}
							</BulkPrintHeaderPrint>
						</BulkPrintHeader>
						{checkedProductsData.nodes.map((item, index) => (
							<BulkPrintItemRow key={index}>
								<span>{item.quantity} </span>
								<span>{item.name} </span>
								<PrintQuantityInput
									type="number"
									value={checkedProductsData.nodes[index].__print_quantity}
									onChange={(e) => {
										const prodNodes = checkedProductsData.nodes
										prodNodes[index].__print_quantity = parseInt(e.target.value)
										setCheckedProductsData({ nodes: prodNodes })
									}}
								/>
							</BulkPrintItemRow>
						))}
					</BulkPrintItems>
				</ConfirmModal>
			)}
			{isUnpublishModalOpen && (
				<ConfirmModal
					title={t('products.modal.unpublish.title')}
					isOpened={isUnpublishModalOpen}
					closeModal={closeUnpublishModal}
					confirm={unpublishBulkMutation}>
					<p>{t('products.modal.unpublish.text', { count: checkedProducts.length })}</p>
				</ConfirmModal>
			)}
			{isShopifyModalOpen && (
				<ConfirmModal
					title={t('products.modal.shopify.title')}
					isOpened={isShopifyModalOpen}
					closeModal={closeShopifyModal}
					confirm={shopifyMutation}>
					<p>{t('products.modal.shopify.text', { count: checkedProducts.length })}</p>
				</ConfirmModal>
			)}
			{isExportModalOpen && (
				<ConfirmModal
					title={t('products.modal.export.title')}
					isOpened={isExportModalOpen}
					closeModal={closeExportModal}
					confirm={handleExportModal}>
					<p>{t('products.modal.export.text', { count: checkedProducts.length })}</p>
				</ConfirmModal>
			)}
			{isFormatModalOpen && (
				<FormatModal
					disableButton={chosenExportFormat === null}
					isOpened={isFormatModalOpen}
					closeModal={closeFormatModal}
					confirm={exportBulkMutation}
					isLoading={isExportLoading}
					onChange={handleFormatChange}
				/>
			)}
			{isPrinting && (
				<div ref={printRef}>
					{Array.isArray(checkedProductsData.nodes) &&
						checkedProductsData.nodes.length > 0 &&
						checkedProductsData.nodes.map((product: any) => {
							return (
								<>
									{Array(product.__print_quantity)
										.fill(true)
										.map((_, i) => (
											<PrintContainer key={`${product.id}-${i}`}>
												<p style={{ fontWeight: 600, fontSize: '12px', lineHeight: '12px' }}>
													<strong style={{ fontSize: '12px' }}>
														{/* {product.price.currency === 'ISK'
															? +product.price.amount
															: product.price.amount}{' '}
														{product.price.currency} */}
														{new Intl.NumberFormat(locale, {
															style: 'currency',
															currency: product.price.currency,
															// currencyDisplay: "narrowSymbol",
															currencyDisplay: 'symbol',
															useGrouping: true
															// minimumFractionDigits: the default for currency formatting is the number of minor unit digits provided by the ISO 4217 currency code list
														}).format(product.price.amount)}
													</strong>
												</p>
												<EllipsisText textCenter>
													<span
														style={{
															fontSize: '12px',
															lineHeight: '12px',
															paddingLeft: '5px',
															paddingRight: '5px'
														}}>
														{product.name}
													</span>
												</EllipsisText>
												<QRCode
													size={40}
													style={{
														height: 'auto',
														maxWidth: '100%',
														textAlign: 'center',
														marginBottom: '1px',
														marginTop: '1px'
													}}
													value={product.sku != null ? product.sku : product.id.toString()}
												/>
												<p style={{ fontSize: '11px', lineHeight: '11px' }}>
													{product.size && (
														<>
															S:&nbsp;<span>{product.size}</span>&nbsp;&mdash;&nbsp;
														</>
													)}
													{product.booking?.booth?.number ? (
														<span>#{product.booking.booth.number}</span>
													) : (
														<span>#{product.booking.ref_nr}</span>
													)}
												</p>
											</PrintContainer>
										))}
								</>
							)
						})}
				</div>
			)}
		</>
	)
}

export default ProductsPage
