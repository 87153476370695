import { useContext, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import tw, { theme } from 'twin.macro'

import { IconContainer } from './styles'
import { IDropdownItemComponent, IDropdownItem } from './types'
import { NavContext } from 'store/NavContext'

const DropdownItem = ({ items, labelText, icon, isActive = false }: IDropdownItemComponent) => {
	const [isOpen, setOpen] = useState(false)
	const navCtx = useContext(NavContext)
	const closeSidebar = () => {
		navCtx?.toggleNavOpened(false)
	}

	return (
		<Wrapper>
			<Label onClick={() => setOpen(!isOpen)} isActive={isActive}>
				<LabelContent>
					<IconContainer isActive={isActive}>{icon}</IconContainer>
					<span>{labelText}</span>
				</LabelContent>
				{isOpen ? <StyledChevronUpIcon /> : <StyledChevronDownIcon />}
			</Label>
			{isOpen && (
				<Content>
					{items !== null &&
						Array.isArray(items) &&
						items.length &&
						items.map((item: IDropdownItem, index: number) =>
							item?.to ? (
								<StyledNavLink key={index} to={item.to} onClick={closeSidebar}>
									{item.label}
								</StyledNavLink>
							) : (
								<Subtitle key={index}>{item.label}</Subtitle>
							)
						)}
				</Content>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div``

const Label = styled.span<{ isActive: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
	padding: 12px 14px;
	font-weight: 600;
	font-size: 0.875rem;
	background-color: ${({ isActive }) => (isActive ? theme`colors.zumthor` : theme`colors.white`)};
	color: ${({ isActive }) => (isActive ? theme`colors.accent` : theme`colors.emperor`)};
`

const LabelContent = styled.span`
	display: flex;
	gap: 1rem;
`

const StyledChevronUpIcon = tw(ChevronUpIcon)`w-lg`

const StyledChevronDownIcon = tw(ChevronDownIcon)`w-lg`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px 14px;
`

const Subtitle = styled.span`
	color: ${theme`colors.boulder`};
	font-weight: 700;
	font-size: 12px;
	padding: 7px 16px;
`

const StyledNavLink = styled(NavLink)`
	color: ${theme`colors.grayChateau`};
	font-size: 13px;
	padding: 7px 16px;
	border-left: 1px solid ${theme`colors.iron`};
	margin: 4px 0;

	&.active {
		color: ${theme`colors.royalBlue`};
		font-weight: 600;
		border-left: 2px solid ${theme`colors.royalBlue`};
	}
`

export default DropdownItem
