import { forwardRef } from 'react'
import styled from 'styled-components'
import { theme } from 'twin.macro'
import { isMobile } from 'react-device-detect'
import DatePicker, { registerLocale } from 'react-datepicker'
import { enGB, is } from 'date-fns/locale'
import { useTranslation } from 'libs'

import CustomDatePickerButton from 'components/Ui/Datepicker/Button'
import Label from 'components/Ui/Label'

interface IDatePickerInput {
	label: string
	selected: any
	dateFormat?: string
	className?: string
	isClearable?: boolean
	disabled?: boolean
	minDate?: Date | undefined
	onChange: (data: any) => void
}

const renderDayContents = (day: any) => {
	return <StyledDay>{day}</StyledDay>
}

const DatePickerInput = forwardRef(
	(
		{
			label,
			selected,
			className,
			dateFormat = 'd MMMM yyyy',
			isClearable = false,
			disabled = false,
			minDate = undefined,
			onChange
		}: IDatePickerInput,
		ref: any
	) => {
		const { i18n } = useTranslation()
		const language = i18n.language === 'en' ? enGB : is

		registerLocale(i18n.language, language)

		return (
			<Wrapper className={className}>
				<Label>{label}</Label>
				<DatePicker
					locale={language}
					selected={selected}
					popperContainer={Popper}
					calendarContainer={Calendar}
					renderDayContents={renderDayContents}
					dateFormat={dateFormat}
					customInput={<CustomDatePickerButton startDate={selected} dateFormat={dateFormat} />}
					minDate={minDate}
					shouldCloseOnSelect={false}
					onChange={onChange}
					withPortal={isMobile}
					ref={ref}
					isClearable={isClearable}
					disabled={disabled}
				/>
			</Wrapper>
		)
	}
)

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.625rem;
`

const StyledDay = styled.span`
	display: block;
`

const Popper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
`

const Calendar = styled.div`
	border-radius: 10px;
	box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
	overflow: hidden;
	border-radius: 0;
	border: none;
	width: 100%;

	.react-datepicker__header {
		background-color: #fff;
		border-bottom: none;
		padding: 0.5rem 0.5rem 0;
	}

	.react-datepicker__month-container {
		font-family: 'Open Sans', sans-serif;
		font-weight: 600;
		font-size: 14px;
		padding: 0.5rem 0.5rem 1rem;
	}

	.react-datepicker__day--keyboard-selected {
		background-color: transparent;
		color: ${theme`colors.boulder`};
	}

	.react-datepicker__day--in-range {
		background-color: #6b76d8;
		border-radius: 3px;
		color: #fff;
	}

	.react-datepicker__day--in-selecting-range {
		background-color: #8d97f4;
		color: #fff;
	}

	.react-datepicker__day-name {
		color: #afafaf;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 12px;
		color: ${theme`colors.doveGray`};
	}

	.react-datepicker__day--excluded {
		background-color: #f48d99;
		color: #fff;
	}

	.react-datepicker__day--disabled {
		color: #ddd;
		background-color: transparent;
	}

	.react-datepicker__day--disabled.react-datepicker__day--selected {
		background-color: ${theme`colors.royalBlue`};
	}
`

export default DatePickerInput
