import { useTranslation } from 'libs'

import { Security, SecurityTitle, Buttons, Button } from './styles'

interface ISecurityCard {
	passwordReset?: boolean
	passwordChange?: boolean
	passwordChangeLink: string
}

const SecurityCard = ({
	passwordChange = true,
	passwordReset = true,
	passwordChangeLink
}: ISecurityCard) => {
	const { t } = useTranslation()

	return (
		<Security>
			<SecurityTitle>{t('profile.security.title')}</SecurityTitle>
			<Buttons>
				{passwordReset && <Button to="/password-reset">{t('profile.security.reset')}</Button>}

				{passwordChange && <Button to={passwordChangeLink}>{t('profile.security.change')}</Button>}
			</Buttons>
		</Security>
	)
}

export default SecurityCard
