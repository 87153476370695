import { forwardRef } from 'react'
import { useTranslation } from 'libs'
import styled from 'styled-components'
import { format } from 'date-fns'
import { enGB, is } from 'date-fns/locale'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { theme } from 'twin.macro'

interface ICustomDatePickerButton {
	value?: any
	onClick?: any
	startDate: Date
	dateFormat: string
}

const CustomDatePickerButton = forwardRef(
	({ value, onClick, startDate, dateFormat }: ICustomDatePickerButton, ref: any) => {
		const { t, i18n } = useTranslation()
		const language = i18n.language === 'is' ? is : enGB

		return (
			<>
				<StyledButton
					type="button"
					className="react-datepicker__button-text"
					onClick={onClick}
					ref={ref}>
					<IconWrapper>
						<FontAwesomeIcon icon={faCalendar} />
					</IconWrapper>
					<span>
						{value
							? `${format(startDate, dateFormat, {
									locale: language
							  })}`
							: t('calendar.placeholder')}
					</span>
					<ChevronIcon icon={faChevronDown} />
				</StyledButton>
			</>
		)
	}
)

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	gap: 10px;
	border-radius: 10px;
	display: inline-flex;
	align-items: center;
	gap: 10px;
	position: relative;
	min-width: 210px;
	box-shadow: 0px 0px 4px rgb(0 0 0 / 8%);
	color: ${theme`colors.dustyGray`};
	width: 100%;
	font-size: 0.875rem;
	background-color: ${theme`colors.white`};

	&:focus {
		outline: none;
	}
`

const IconWrapper = styled.div`
	min-width: 36px;
	height: 36px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const ChevronIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 10px;
`

export default CustomDatePickerButton
