import { useTranslation } from 'libs'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'

import Header from 'components/Header'
import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import { useToast } from 'hooks'
import { useAxiosPrivate } from 'hooks'
import { Wrapper } from './styles'
import { useNavigate } from 'react-router-dom'

const ChangePassword = () => {
	const { t } = useTranslation()
	const {
		handleSubmit,
		register,
		setError,
		formState: { errors }
	} = useForm()
	const successMessage = useToast({ type: 'success', message: t('changePassword.form.success') })
	const failMessage = useToast({ type: 'error', message: t('changePassword.form.error') })
	const axiosPrivate = useAxiosPrivate()
	const navigate = useNavigate()

	const { mutate: changePasswordMutation, isLoading: changePasswordLoading } = useMutation(
		(data) => {
			return axiosPrivate.post('/me/change_password/', data)
		},
		{
			onSuccess: () => {
				successMessage()
				navigate('/profile')
			},
			onError: (res: any) => {
				if (res?.response?.data) {
					for (const [key, value] of Object.entries(res.response.data)) {
						setError(key, { message: value as string })
					}
				}

				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		changePasswordMutation(data)
	}

	return (
		<>
			<Header title={t('changePassword.title')} />
			<Wrapper>
				<Form
					submitText={t('changePassword.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={changePasswordLoading}>
					<Input
						id="old_password"
						type="password"
						label={t('changePassword.form.oldPassword')}
						{...register('old_password')}
						error={(errors as any).old_password}
					/>
					<Input
						id="password"
						type="password"
						label={t('changePassword.form.password')}
						{...register('password')}
						error={(errors as any).password}
					/>
					<Input
						id="password2"
						type="password"
						label={t('changePassword.form.password2')}
						{...register('password2')}
						error={(errors as any).password2}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

export default ChangePassword
