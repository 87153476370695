import { useForm } from 'react-hook-form'
import { useTranslation } from 'libs'
import { useMutation, useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAxiosPrivate } from 'hooks'
import Header from 'components/Header'
import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'hooks'
import { Wrapper } from './styles'
// import { addCategoryValidationSchema } from 'schemas/validation'

const AddCategory = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const successMessage = useToast({ type: 'success', message: t('addCategory.form.success') })
	const failMessage = useToast({ type: 'error', message: t('addCategory.form.error') })
	const addCategoryValidationSchema = yup.object({
		name: yup.string().required(t('validation.addCategory.name.required'))
	})
	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(addCategoryValidationSchema)
	})

	const { mutate: createCategory, isLoading: createCategoryLoading } = useMutation(
		(data) => {
			return axiosPrivate.post('/categories/', data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchCategories')
				successMessage()

				navigate('/categories')
			},
			onError: () => {
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		createCategory(data)
	}

	return (
		<>
			<Header title={t('addCategory.title')} />
			<Wrapper>
				<Form
					submitText={t('addCategory.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={createCategoryLoading}>
					<Input
						id="name"
						label={t('addCategory.form.name')}
						{...register('name')}
						error={(errors as any).name}
						required={true}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

export default AddCategory
